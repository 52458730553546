'use client';

import { Container } from 'react-bootstrap';

import { MobileRandomSection } from './MobileRandomSection';
import { useTranslations } from 'next-intl';

import { useRandomMovie } from '@/views/HomeView/RandomMovie/useRandomMovie';

import { SectionHeading } from '@/components/SectionHeading';

import styles from '@/views/HomeView/RandomMovie/RandomMovie.module.scss';
import { RandomSection } from '@/views/HomeView/RandomMovie/RandomSection';

// =================================================================

export const RandomMovieSection = () => {
  const t = useTranslations('page.home');

  const { isLoading, movieDetails, refetch } = useRandomMovie();

  return (
    <section className={styles.randomMovieSection}>
      <Container>
        <SectionHeading className={styles.title}>{t('randomMovie')}</SectionHeading>

        <MobileRandomSection
          isLoading={isLoading}
          movieDetails={movieDetails}
          refetch={refetch}
          className={styles.mobileSectionWrapper}
        />
        <RandomSection
          isLoading={isLoading}
          movieDetails={movieDetails}
          refetch={refetch}
          className={styles.sectionWrapper}
        />
      </Container>
    </section>
  );
};
