import {
  useInfiniteQuery,
  QueryKey,
  QueryFunction,
  GetNextPageParamFunction,
  PlaceholderDataFunction,
} from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { throttle } from 'lodash';

import type { InfiniteLoaderData } from '@/api/types/common.types';

// =================================================================

type SelectFunction<TQueryFnData> = (params: {
  pageParams: number[];
  pages: TQueryFnData[];
}) => TQueryFnData;

type UseInfiniteLoaderConfig<TQueryData> = {
  queryKey: QueryKey;
  queryFn: QueryFunction<TQueryData, QueryKey, number>;
  select?: SelectFunction<TQueryData>;
  getNextPageParam?: GetNextPageParamFunction<number, TQueryData>;
  pagesPerClick?: number;
  intersectionObserverMargin?: number;
  initialPageParam?: number;
  enabled?: boolean;
  staleTime?: number;
  placeholderData?: PlaceholderDataFunction<TQueryData | any>;
};

export type ResponseInfiniteLoaderData<TQueryData> = {
  pageParams: number[];
  pages: InfiniteLoaderData<TQueryData, any>[];
};

// =================================================================

const selectFunction = <TQueryData>({ pages }: ResponseInfiniteLoaderData<TQueryData>) => {
  return {
    list: pages.reduce(
      (prevValue: TQueryData[], currentValue) => [...prevValue, ...currentValue.list],
      [],
    ),
    total: pages[0].total,
    currentPage: pages[0].currentPage,
    lastPage: pages[0].lastPage,
    extra: pages[0].extra,
  };
};

// =================================================================

const getNextPageParam = <TQueryData>({
  currentPage,
  lastPage,
}: InfiniteLoaderData<TQueryData, any>) => {
  return currentPage < lastPage ? currentPage + 1 : undefined;
};

// =================================================================

export const useInfiniteLoader = <TQueryListData, TExtraData>(
  config: UseInfiniteLoaderConfig<InfiniteLoaderData<TQueryListData, TExtraData>>,
) => {
  const {
    queryKey,
    queryFn,
    select = selectFunction<TQueryListData>,
    intersectionObserverMargin = 400,
    pagesPerClick = 5,
    initialPageParam = 2,
    enabled = true,
    staleTime = Infinity,
    placeholderData,
  } = config;

  const { ref, inView } = useInView({
    rootMargin: `${intersectionObserverMargin}px`,
  });

  const [pagesLimit, setPagesLimit] = useState(0);

  const {
    data,
    isFetching: isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    status,
    refetch,
    isLoading: isLoadingNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn,
    initialPageParam,
    getNextPageParam,
    select,
    staleTime,
    enabled,
    retry: 1,
    placeholderData,
  });

  const handleButtonClick = () => {
    setPagesLimit(pagesPerClick);
  };

  const throttledFetch = useMemo(
    () =>
      throttle(() => {
        setPagesLimit(prevValue => prevValue - 1);
        fetchNextPage();
      }, 500),
    [fetchNextPage],
  );

  useEffect(() => {
    if (inView && hasNextPage && pagesLimit && !isFetchingNextPage) {
      throttledFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, pagesLimit]);

  const isLoading = isLoadingNextPage || pagesLimit > 0 || status === 'pending';
  const isFetching = isFetchingNextPage || pagesLimit > 0;

  return {
    ref,
    isLoading,
    isFetching,
    handleButtonClick,
    hasNextPage,
    data,
    status,
    refetch,
  };
};
