import { InfiniteLoaderData, PaginationParams } from '@/api/types/common.types';
import type {
  HomeCollectionsResponse,
  UnfinishedMovie,
  WebGenres,
  HomeCollectionList,
  Catalog,
} from '@/api/types/home.types';
import type {
  MovieCollection,
  Movie,
  Country,
  MovieDetails,
  MovieDetailsInfo,
} from '@/api/types/movie.types';

import { httpClient } from '@/libs/http-client';

import { RatingMovieEnum } from '@/enums/module-types.enums';

export class HomeApi {
  static collections = async (params: PaginationParams) => {
    const { data } = await httpClient.get<HomeCollectionsResponse>('/home/movie-collections', {
      params,
    });

    const transformedData: InfiniteLoaderData<HomeCollectionList, unknown> = {
      currentPage: data.currentPage,
      lastPage: data.lastPage,
      total: data.total,
      list: data.list,
    };

    return transformedData;
  };

  // =================================================================

  static extractCollectionMovies = (movies: MovieCollection[]): Movie[] => {
    return movies.map(movie => ({
      id: movie.id,
      card_type: movie.cardType,
      age_limit: movie.age_limit,
      genres: movie.genres,
      is_free: movie.is_free,
      is_new: movie.is_new,
      is_serial: movie.is_serial,
      module_id: movie.moduleId,
      poster: movie.poster,
      quality: movie.quality,
      title: movie.title,
      title_en: movie.title_en,
      year: movie.year,
      countries: movie.countries.map(country => ({
        id: country.id,
        title: country.title,
        slug: country.title,
      })) as Country[],
      rating: {
        [RatingMovieEnum.IMDB]: {
          rating: movie.rating?.imdb_rating || 0,
          count: movie.rating?.imdb_rating_count,
        },
        [RatingMovieEnum.KP]: {
          rating: movie.rating?.kp_rating || 0,
          count: movie.rating?.kp_rating_count,
        },
      },
      is_download: false,
    }));
  };

  // =================================================================

  static fetchUnfinishedMovies = async () => {
    const { data } = await httpClient.get<UnfinishedMovie[]>('/home/last-position');

    return data;
  };

  // =================================================================

  static fetchHomeGenres = async () => {
    const { data } = await httpClient.get<WebGenres[]>('/genre', {
      baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
    });

    return data;
  };

  // =================================================================
  static fetchHomeCatalogs = async () => {
    const { data } = await httpClient.get<Catalog[]>('/catalog', {
      baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
    });

    return data;
  };

  // =================================================================
  static fetchHomeRandomMovie = async () => {
    return await httpClient.get<
      unknown,
      {
        info: MovieDetailsInfo;
        data: MovieDetails & {
          files: MovieDetailsInfo & { id: number }[];
        };
      }
    >('/random-movies-collection', {
      baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
    });
  };
}
