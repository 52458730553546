import type { SVGProps } from '@/types/common.types';

const EnSVG = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_830_19707)">
        <path d="M24 5.53833H0V7.38427H24V5.53833Z" fill="#F5F5F5" />
        <path
          d="M22.7471 1.84619H1.25362C0.798555 2.38 0.448352 3.00593 0.235352 3.69236H23.7654C23.5524 3.00593 23.2022 2.38 22.7471 1.84619Z"
          fill="#F5F5F5"
        />
        <path d="M24 9.23059H0V11.0765H24V9.23059Z" fill="#F5F5F5" />
        <path d="M24 12.923H0V14.7689H24V12.923Z" fill="#F5F5F5" />
        <path d="M24 16.6154H0V18.4613H24V16.6154Z" fill="#F5F5F5" />
        <path
          d="M23.7654 20.3077H0.235352C0.448305 20.9942 0.798555 21.6201 1.25362 22.1539H22.7471C23.2022 21.6201 23.5524 20.9942 23.7654 20.3077Z"
          fill="#F5F5F5"
        />
        <path
          d="M18.7497 0H5.24968C3.64899 0 2.21588 0.716578 1.25293 1.84617H22.7464C21.7835 0.716578 20.3504 0 18.7497 0Z"
          fill="#FF4B55"
        />
        <path d="M24 7.38464H0V9.23058H24V7.38464Z" fill="#FF4B55" />
        <path
          d="M24 5.25004C24 4.70765 23.9177 4.18452 23.765 3.69238H0.234984C0.0822656 4.18452 0 4.70765 0 5.25004V5.53851H24V5.25004Z"
          fill="#FF4B55"
        />
        <path d="M24 11.077H0V12.923H24V11.077Z" fill="#FF4B55" />
        <path
          d="M24 18.75V18.4615H0V18.75C0 19.2924 0.0822656 19.8155 0.234984 20.3077H23.765C23.9177 19.8155 24 19.2924 24 18.75Z"
          fill="#FF4B55"
        />
        <path
          d="M22.7464 22.1538H1.25293C2.21588 23.2834 3.64899 24 5.24968 24H18.7497C20.3504 24 21.7835 23.2834 22.7464 22.1538Z"
          fill="#FF4B55"
        />
        <path d="M24 14.7694H0V16.6153H24V14.7694Z" fill="#FF4B55" />
        <path d="M12.9231 0H5.25C2.3505 0 0 2.3505 0 5.25V12.9231H12.9231V0Z" fill="#41479B" />
        <path
          d="M1.39468 2.01857C1.3824 2.05743 1.4266 2.08958 1.45979 2.06586L1.71586 1.88286L1.97194 2.06586C2.00508 2.08958 2.04933 2.05743 2.03705 2.01857L1.94213 1.71847L2.19535 1.53149C2.22811 1.50726 2.21124 1.45527 2.17046 1.45494L1.85574 1.45251L1.80202 1.29144C1.66711 1.40905 1.53844 1.53355 1.41624 1.66419L1.48969 1.71847L1.39468 2.01857Z"
          fill="#F5F5F5"
        />
        <path
          d="M1.75584 3.64473L1.8554 3.94332L2.17012 3.94576C2.21085 3.94609 2.22778 3.99807 2.19501 4.0223L1.94179 4.20929L2.03671 4.50938C2.049 4.54824 2.00479 4.58035 1.9716 4.55668L1.71553 4.37363L1.45945 4.55668C1.42631 4.5804 1.38206 4.54824 1.39434 4.50938L1.48926 4.20929L1.23604 4.0223C1.20328 3.99812 1.22015 3.94609 1.26089 3.94576L1.5756 3.94332L1.67517 3.64473C1.68825 3.60605 1.74295 3.60605 1.75584 3.64473Z"
          fill="#F5F5F5"
        />
        <path
          d="M1.75584 6.4073L1.8554 6.70589L2.17012 6.70833C2.21085 6.70866 2.22778 6.76064 2.19501 6.78488L1.94179 6.97186L2.03671 7.27196C2.049 7.31082 2.00479 7.34292 1.9716 7.31925L1.71553 7.13625L1.45945 7.31925C1.42631 7.34292 1.38206 7.31082 1.39434 7.27196L1.48926 6.97186L1.23604 6.78488C1.20328 6.76069 1.22015 6.70866 1.26089 6.70833L1.5756 6.70589L1.67517 6.4073C1.68825 6.36863 1.74295 6.36863 1.75584 6.4073Z"
          fill="#F5F5F5"
        />
        <path
          d="M1.75584 8.94539L1.8554 9.24398L2.17012 9.24642C2.21085 9.24674 2.22778 9.29873 2.19501 9.32296L1.94179 9.50995L2.03671 9.81004C2.049 9.8489 2.00479 9.88101 1.9716 9.85734L1.71553 9.67429L1.45945 9.85734C1.42631 9.88101 1.38206 9.8489 1.39434 9.81004L1.48926 9.50995L1.23604 9.32296C1.20328 9.29878 1.22015 9.24674 1.26089 9.24642L1.5756 9.24398L1.67517 8.94539C1.68825 8.90671 1.74295 8.90671 1.75584 8.94539Z"
          fill="#F5F5F5"
        />
        <path
          d="M1.75584 11.255L1.8554 11.5535L2.17012 11.556C2.21085 11.5563 2.22778 11.6083 2.19501 11.6325L1.94179 11.8195L2.03671 12.1196C2.049 12.1585 2.00479 12.1906 1.9716 12.1669L1.71553 11.9839L1.45945 12.1669C1.42631 12.1906 1.38206 12.1585 1.39434 12.1196L1.48926 11.8195L1.23604 11.6325C1.20328 11.6083 1.22015 11.5563 1.26089 11.556L1.5756 11.5535L1.67517 11.255C1.68825 11.2163 1.74295 11.2163 1.75584 11.255Z"
          fill="#F5F5F5"
        />
        <path
          d="M2.96859 2.33345L3.06815 2.63204L3.38287 2.63448C3.4236 2.63481 3.44053 2.68679 3.40776 2.71098L3.15454 2.89796L3.24946 3.19806C3.26175 3.23692 3.21754 3.26907 3.18435 3.24535L2.92837 3.06231L2.67229 3.24535C2.63915 3.26907 2.5949 3.23692 2.60718 3.19806L2.7021 2.89796L2.44893 2.71098C2.41617 2.68679 2.43304 2.63476 2.47378 2.63448L2.7885 2.63204L2.88806 2.33345C2.901 2.29478 2.9557 2.29478 2.96859 2.33345Z"
          fill="#F5F5F5"
        />
        <path
          d="M2.96771 5.09602L3.06727 5.39461L3.38199 5.39705C3.42272 5.39738 3.43964 5.44936 3.40688 5.4736L3.15366 5.66058L3.24858 5.96068C3.26086 5.99954 3.21666 6.03169 3.18347 6.00797L2.92739 5.82493L2.67132 6.00797C2.63817 6.03169 2.59392 5.99954 2.60621 5.96068L2.70113 5.66058L2.44796 5.4736C2.41519 5.44941 2.43207 5.39738 2.4728 5.39705L2.78752 5.39461L2.88708 5.09602C2.90011 5.05735 2.95482 5.05735 2.96771 5.09602Z"
          fill="#F5F5F5"
        />
        <path
          d="M2.96771 7.7205L3.06727 8.01909L3.38199 8.02153C3.42272 8.02186 3.43964 8.07384 3.40688 8.09807L3.15366 8.28506L3.24858 8.58515C3.26086 8.62401 3.21666 8.65612 3.18347 8.63245L2.92739 8.4494L2.67132 8.63245C2.63817 8.65617 2.59392 8.62401 2.60621 8.58515L2.70113 8.28506L2.44796 8.09807C2.41519 8.07389 2.43207 8.02186 2.4728 8.02153L2.78752 8.01909L2.88708 7.7205C2.90011 7.68187 2.95482 7.68187 2.96771 7.7205Z"
          fill="#F5F5F5"
        />
        <path
          d="M2.96771 10.0775L3.06727 10.3761L3.38199 10.3785C3.42272 10.3788 3.43964 10.4308 3.40688 10.455L3.15366 10.642L3.24858 10.9421C3.26086 10.981 3.21666 11.0131 3.18347 10.9894L2.92739 10.8064L2.67132 10.9894C2.63817 11.0131 2.59392 10.981 2.60621 10.9421L2.70113 10.642L2.44796 10.455C2.41519 10.4309 2.43207 10.3788 2.4728 10.3785L2.78752 10.3761L2.88708 10.0775C2.90011 10.0388 2.95482 10.0388 2.96771 10.0775Z"
          fill="#F5F5F5"
        />
        <path
          d="M4.1806 1.15397L4.28016 1.45256L4.59488 1.455C4.63561 1.45533 4.65253 1.50731 4.61977 1.5315L4.36655 1.71848L4.46147 2.01858C4.47375 2.05744 4.42955 2.08955 4.39636 2.06587L4.14028 1.88283L3.88421 2.06587C3.85107 2.08959 3.80682 2.05744 3.8191 2.01858L3.91402 1.71848L3.66085 1.5315C3.62808 1.50731 3.64496 1.45528 3.68569 1.455L4.00041 1.45256L4.09997 1.15397C4.113 1.11534 4.16771 1.11534 4.1806 1.15397Z"
          fill="#F5F5F5"
        />
        <path
          d="M4.1806 3.64473L4.28016 3.94332L4.59488 3.94576C4.63561 3.94609 4.65253 3.99807 4.61977 4.0223L4.36655 4.20929L4.46147 4.50938C4.47375 4.54824 4.42955 4.58035 4.39636 4.55668L4.14028 4.37363L3.88421 4.55668C3.85107 4.5804 3.80682 4.54824 3.8191 4.50938L3.91402 4.20929L3.66085 4.0223C3.62808 3.99812 3.64496 3.94609 3.68569 3.94576L4.00041 3.94332L4.09997 3.64473C4.113 3.60605 4.16771 3.60605 4.1806 3.64473Z"
          fill="#F5F5F5"
        />
        <path
          d="M4.1806 6.4073L4.28016 6.70589L4.59488 6.70833C4.63561 6.70866 4.65253 6.76064 4.61977 6.78488L4.36655 6.97186L4.46147 7.27196C4.47375 7.31082 4.42955 7.34292 4.39636 7.31925L4.14028 7.13625L3.88421 7.31925C3.85107 7.34292 3.80682 7.31082 3.8191 7.27196L3.91402 6.97186L3.66085 6.78488C3.62808 6.76069 3.64496 6.70866 3.68569 6.70833L4.00041 6.70589L4.09997 6.4073C4.113 6.36863 4.16771 6.36863 4.1806 6.4073Z"
          fill="#F5F5F5"
        />
        <path
          d="M4.1806 8.94539L4.28016 9.24398L4.59488 9.24642C4.63561 9.24674 4.65253 9.29873 4.61977 9.32296L4.36655 9.50995L4.46147 9.81004C4.47375 9.8489 4.42955 9.88101 4.39636 9.85734L4.14028 9.67429L3.88421 9.85734C3.85107 9.88101 3.80682 9.8489 3.8191 9.81004L3.91402 9.50995L3.66085 9.32296C3.62808 9.29878 3.64496 9.24674 3.68569 9.24642L4.00041 9.24398L4.09997 8.94539C4.113 8.90671 4.16771 8.90671 4.1806 8.94539Z"
          fill="#F5F5F5"
        />
        <path
          d="M4.1806 11.255L4.28016 11.5535L4.59488 11.556C4.63561 11.5563 4.65253 11.6083 4.61977 11.6325L4.36655 11.8195L4.46147 12.1196C4.47375 12.1585 4.42955 12.1906 4.39636 12.1669L4.14028 11.9839L3.88421 12.1669C3.85107 12.1906 3.80682 12.1585 3.8191 12.1196L3.91402 11.8195L3.66085 11.6325C3.62808 11.6083 3.64496 11.5563 3.68569 11.556L4.00041 11.5535L4.09997 11.255C4.113 11.2163 4.16771 11.2163 4.1806 11.255Z"
          fill="#F5F5F5"
        />
        <path
          d="M5.39256 2.33345L5.49212 2.63204L5.80684 2.63448C5.84757 2.63481 5.86449 2.68679 5.83168 2.71098L5.57851 2.89796L5.67343 3.19806C5.68571 3.23692 5.64151 3.26907 5.60832 3.24535L5.35224 3.06231L5.09616 3.24535C5.06302 3.26907 5.01877 3.23692 5.03106 3.19806L5.12598 2.89796L4.87276 2.71098C4.83999 2.68679 4.85687 2.63476 4.89765 2.63448L5.21237 2.63204L5.31193 2.33345C5.32501 2.29478 5.37966 2.29478 5.39256 2.33345Z"
          fill="#F5F5F5"
        />
        <path
          d="M5.39256 5.09602L5.49212 5.39461L5.80684 5.39705C5.84757 5.39738 5.86449 5.44936 5.83168 5.4736L5.57851 5.66058L5.67343 5.96068C5.68571 5.99954 5.64151 6.03169 5.60832 6.00797L5.35224 5.82493L5.09616 6.00797C5.06302 6.03169 5.01877 5.99954 5.03106 5.96068L5.12598 5.66058L4.87276 5.4736C4.83999 5.44941 4.85687 5.39738 4.89765 5.39705L5.21237 5.39461L5.31193 5.09602C5.32501 5.05735 5.37966 5.05735 5.39256 5.09602Z"
          fill="#F5F5F5"
        />
        <path
          d="M5.39256 7.7205L5.49212 8.01909L5.80684 8.02153C5.84757 8.02186 5.86449 8.07384 5.83168 8.09807L5.57851 8.28506L5.67343 8.58515C5.68571 8.62401 5.64151 8.65612 5.60832 8.63245L5.35224 8.4494L5.09616 8.63245C5.06302 8.65617 5.01877 8.62401 5.03106 8.58515L5.12598 8.28506L4.87276 8.09807C4.83999 8.07389 4.85687 8.02186 4.89765 8.02153L5.21237 8.01909L5.31193 7.7205C5.32501 7.68187 5.37966 7.68187 5.39256 7.7205Z"
          fill="#F5F5F5"
        />
        <path
          d="M5.39256 10.0775L5.49212 10.3761L5.80684 10.3785C5.84757 10.3788 5.86449 10.4308 5.83168 10.455L5.57851 10.642L5.67343 10.9421C5.68571 10.981 5.64151 11.0131 5.60832 10.9894L5.35224 10.8064L5.09616 10.9894C5.06302 11.0131 5.01877 10.981 5.03106 10.9421L5.12598 10.642L4.87276 10.455C4.83999 10.4309 4.85687 10.3788 4.89765 10.3785L5.21237 10.3761L5.31193 10.0775C5.32501 10.0388 5.37966 10.0388 5.39256 10.0775Z"
          fill="#F5F5F5"
        />
        <path
          d="M6.6054 1.15397L6.70496 1.45256L7.01968 1.455C7.06041 1.45533 7.07734 1.50731 7.04452 1.5315L6.79135 1.71848L6.88627 2.01858C6.89856 2.05744 6.85435 2.08955 6.82116 2.06587L6.56509 1.88283L6.30901 2.06587C6.27587 2.08959 6.23162 2.05744 6.2439 2.01858L6.33882 1.71848L6.08565 1.5315C6.05288 1.50731 6.06976 1.45528 6.11054 1.455L6.42526 1.45256L6.52482 1.15397C6.53785 1.11534 6.59251 1.11534 6.6054 1.15397Z"
          fill="#F5F5F5"
        />
        <path
          d="M6.60535 3.64473L6.70491 3.94333L7.01963 3.94576C7.06037 3.94609 7.07729 3.99808 7.04448 4.02231L6.7913 4.2093L6.88627 4.50934C6.89855 4.5482 6.85435 4.58031 6.82116 4.55664L6.56509 4.37359L6.30901 4.55664C6.27587 4.58036 6.23162 4.5482 6.2439 4.50934L6.33882 4.20925L6.08565 4.02226C6.05288 3.99808 6.06976 3.94605 6.11054 3.94572L6.42526 3.94328L6.52482 3.64469C6.5378 3.60606 6.59246 3.60606 6.60535 3.64473Z"
          fill="#F5F5F5"
        />
        <path
          d="M6.6054 6.4073L6.70496 6.70589L7.01968 6.70833C7.06041 6.70866 7.07734 6.76064 7.04452 6.78488L6.79135 6.97186L6.88627 7.27196C6.89856 7.31082 6.85435 7.34292 6.82116 7.31925L6.56509 7.13625L6.30901 7.31925C6.27587 7.34292 6.23162 7.31082 6.2439 7.27196L6.33882 6.97186L6.08565 6.78488C6.05288 6.76069 6.06976 6.70866 6.11054 6.70833L6.42526 6.70589L6.52482 6.4073C6.53785 6.36863 6.59251 6.36863 6.6054 6.4073Z"
          fill="#F5F5F5"
        />
        <path
          d="M6.6054 8.94539L6.70496 9.24398L7.01968 9.24642C7.06041 9.24674 7.07734 9.29873 7.04452 9.32296L6.79135 9.50995L6.88627 9.81004C6.89856 9.8489 6.85435 9.88101 6.82116 9.85734L6.56509 9.67429L6.30901 9.85734C6.27587 9.88101 6.23162 9.8489 6.2439 9.81004L6.33882 9.50995L6.08565 9.32296C6.05288 9.29878 6.06976 9.24674 6.11054 9.24642L6.42526 9.24398L6.52482 8.94539C6.53785 8.90671 6.59251 8.90671 6.6054 8.94539Z"
          fill="#F5F5F5"
        />
        <path
          d="M6.6054 11.255L6.70496 11.5535L7.01968 11.556C7.06041 11.5563 7.07734 11.6083 7.04452 11.6325L6.79135 11.8195L6.88627 12.1196C6.89856 12.1585 6.85435 12.1906 6.82116 12.1669L6.56509 11.9839L6.30901 12.1669C6.27587 12.1906 6.23162 12.1585 6.2439 12.1196L6.33882 11.8195L6.08565 11.6325C6.05288 11.6083 6.06976 11.5563 6.11054 11.556L6.42526 11.5535L6.52482 11.255C6.53785 11.2163 6.59251 11.2163 6.6054 11.255Z"
          fill="#F5F5F5"
        />
        <path
          d="M7.81736 2.33345L7.91692 2.63204L8.23164 2.63448C8.27238 2.63481 8.2893 2.68679 8.25649 2.71098L8.00327 2.89796L8.09819 3.19806C8.11047 3.23692 8.06622 3.26907 8.03308 3.24535L7.777 3.06231L7.52092 3.24535C7.48778 3.26907 7.44353 3.23692 7.45582 3.19806L7.55074 2.89796L7.29757 2.71098C7.2648 2.68679 7.28167 2.63476 7.32241 2.63448L7.63713 2.63204L7.73669 2.33345C7.74982 2.29478 7.80447 2.29478 7.81736 2.33345Z"
          fill="#F5F5F5"
        />
        <path
          d="M7.81736 5.09602L7.91692 5.39461L8.23164 5.39705C8.27238 5.39738 8.2893 5.44936 8.25649 5.4736L8.00327 5.66058L8.09819 5.96068C8.11047 5.99954 8.06622 6.03169 8.03308 6.00797L7.777 5.82493L7.52092 6.00797C7.48778 6.03169 7.44353 5.99954 7.45582 5.96068L7.55074 5.66058L7.29757 5.4736C7.2648 5.44941 7.28167 5.39738 7.32241 5.39705L7.63713 5.39461L7.73669 5.09602C7.74982 5.05735 7.80447 5.05735 7.81736 5.09602Z"
          fill="#F5F5F5"
        />
        <path
          d="M7.81736 7.7205L7.91692 8.01909L8.23164 8.02153C8.27238 8.02186 8.2893 8.07384 8.25649 8.09807L8.00327 8.28506L8.09819 8.58515C8.11047 8.62401 8.06622 8.65612 8.03308 8.63245L7.777 8.4494L7.52092 8.63245C7.48778 8.65617 7.44353 8.62401 7.45582 8.58515L7.55074 8.28506L7.29757 8.09807C7.2648 8.07389 7.28167 8.02186 7.32241 8.02153L7.63713 8.01909L7.73669 7.7205C7.74982 7.68187 7.80447 7.68187 7.81736 7.7205Z"
          fill="#F5F5F5"
        />
        <path
          d="M7.81736 10.0775L7.91692 10.3761L8.23164 10.3785C8.27238 10.3788 8.2893 10.4308 8.25649 10.455L8.00327 10.642L8.09819 10.9421C8.11047 10.981 8.06622 11.0131 8.03308 10.9894L7.777 10.8064L7.52092 10.9894C7.48778 11.0131 7.44353 10.981 7.45582 10.9421L7.55074 10.642L7.29757 10.455C7.2648 10.4309 7.28167 10.3788 7.32241 10.3785L7.63713 10.3761L7.73669 10.0775C7.74982 10.0388 7.80447 10.0388 7.81736 10.0775Z"
          fill="#F5F5F5"
        />
        <path
          d="M9.02932 1.15397L9.12888 1.45256L9.4436 1.455C9.48434 1.45533 9.50126 1.50731 9.46845 1.5315L9.21523 1.71848L9.31015 2.01858C9.32243 2.05744 9.27818 2.08955 9.24504 2.06587L8.98896 1.88283L8.73288 2.06587C8.69974 2.08959 8.65549 2.05744 8.66777 2.01858L8.7627 1.71848L8.50948 1.5315C8.47671 1.50731 8.49359 1.45528 8.53437 1.455L8.84909 1.45256L8.94865 1.15397C8.96177 1.11534 9.01643 1.11534 9.02932 1.15397Z"
          fill="#F5F5F5"
        />
        <path
          d="M9.02932 3.64473L9.12888 3.94332L9.4436 3.94576C9.48434 3.94609 9.50126 3.99807 9.46845 4.0223L9.21523 4.20929L9.31015 4.50938C9.32243 4.54824 9.27818 4.58035 9.24504 4.55668L8.98896 4.37363L8.73288 4.55668C8.69974 4.5804 8.65549 4.54824 8.66777 4.50938L8.7627 4.20929L8.50948 4.0223C8.47671 3.99812 8.49359 3.94609 8.53437 3.94576L8.84909 3.94332L8.94865 3.64473C8.96177 3.60605 9.01643 3.60605 9.02932 3.64473Z"
          fill="#F5F5F5"
        />
        <path
          d="M9.02932 6.4073L9.12888 6.70589L9.4436 6.70833C9.48434 6.70866 9.50126 6.76064 9.46845 6.78488L9.21523 6.97186L9.31015 7.27196C9.32243 7.31082 9.27818 7.34292 9.24504 7.31925L8.98896 7.13625L8.73288 7.31925C8.69974 7.34292 8.65549 7.31082 8.66777 7.27196L8.7627 6.97186L8.50948 6.78488C8.47671 6.76069 8.49359 6.70866 8.53437 6.70833L8.84909 6.70589L8.94865 6.4073C8.96177 6.36863 9.01643 6.36863 9.02932 6.4073Z"
          fill="#F5F5F5"
        />
        <path
          d="M9.02932 8.94539L9.12888 9.24398L9.4436 9.24642C9.48434 9.24674 9.50126 9.29873 9.46845 9.32296L9.21523 9.50995L9.31015 9.81004C9.32243 9.8489 9.27818 9.88101 9.24504 9.85734L8.98896 9.67429L8.73288 9.85734C8.69974 9.88101 8.65549 9.8489 8.66777 9.81004L8.7627 9.50995L8.50948 9.32296C8.47671 9.29878 8.49359 9.24674 8.53437 9.24642L8.84909 9.24398L8.94865 8.94539C8.96177 8.90671 9.01643 8.90671 9.02932 8.94539Z"
          fill="#F5F5F5"
        />
        <path
          d="M9.02932 11.255L9.12888 11.5535L9.4436 11.556C9.48434 11.5563 9.50126 11.6083 9.46845 11.6325L9.21523 11.8195L9.31015 12.1196C9.32243 12.1585 9.27818 12.1906 9.24504 12.1669L8.98896 11.9839L8.73288 12.1669C8.69974 12.1906 8.65549 12.1585 8.66777 12.1196L8.7627 11.8195L8.50948 11.6325C8.47671 11.6083 8.49359 11.5563 8.53437 11.556L8.84909 11.5535L8.94865 11.255C8.96177 11.2163 9.01643 11.2163 9.02932 11.255Z"
          fill="#F5F5F5"
        />
        <path
          d="M10.2422 2.33345L10.3417 2.63204L10.6565 2.63448C10.6972 2.63481 10.7142 2.68679 10.6813 2.71098L10.4281 2.89796L10.523 3.19806C10.5353 3.23692 10.4911 3.26907 10.4579 3.24535L10.2019 3.06231L9.94578 3.24535C9.91264 3.26907 9.86839 3.23692 9.88067 3.19806L9.97559 2.89796L9.72237 2.71098C9.6896 2.68679 9.70648 2.63476 9.74721 2.63448L10.062 2.63204L10.1615 2.33345C10.1746 2.29478 10.2293 2.29478 10.2422 2.33345Z"
          fill="#F5F5F5"
        />
        <path
          d="M10.2422 5.09602L10.3417 5.39461L10.6565 5.39705C10.6972 5.39738 10.7142 5.44936 10.6813 5.4736L10.4281 5.66058L10.523 5.96068C10.5353 5.99954 10.4911 6.03169 10.4579 6.00797L10.2019 5.82493L9.94578 6.00797C9.91264 6.03169 9.86839 5.99954 9.88067 5.96068L9.97559 5.66058L9.72237 5.4736C9.6896 5.44941 9.70648 5.39738 9.74721 5.39705L10.062 5.39461L10.1615 5.09602C10.1746 5.05735 10.2293 5.05735 10.2422 5.09602Z"
          fill="#F5F5F5"
        />
        <path
          d="M10.2422 7.7205L10.3417 8.01909L10.6565 8.02153C10.6972 8.02186 10.7142 8.07384 10.6813 8.09807L10.4281 8.28506L10.523 8.58515C10.5353 8.62401 10.4911 8.65612 10.4579 8.63245L10.2019 8.4494L9.94578 8.63245C9.91264 8.65617 9.86839 8.62401 9.88067 8.58515L9.97559 8.28506L9.72237 8.09807C9.6896 8.07389 9.70648 8.02186 9.74721 8.02153L10.062 8.01909L10.1615 7.7205C10.1746 7.68187 10.2293 7.68187 10.2422 7.7205Z"
          fill="#F5F5F5"
        />
        <path
          d="M10.2422 10.0775L10.3417 10.3761L10.6565 10.3785C10.6972 10.3788 10.7142 10.4308 10.6813 10.455L10.4281 10.642L10.523 10.9421C10.5353 10.981 10.4911 11.0131 10.4579 10.9894L10.2019 10.8064L9.94578 10.9894C9.91264 11.0131 9.86839 10.981 9.88067 10.9421L9.97559 10.642L9.72237 10.455C9.6896 10.4309 9.70648 10.3788 9.74721 10.3785L10.062 10.3761L10.1615 10.0775C10.1746 10.0388 10.2293 10.0388 10.2422 10.0775Z"
          fill="#F5F5F5"
        />
        <path
          d="M11.4541 1.15397L11.5536 1.45256L11.8684 1.455C11.9091 1.45533 11.926 1.50731 11.8933 1.5315L11.64 1.71848L11.735 2.01858C11.7472 2.05744 11.703 2.08955 11.6698 2.06587L11.4138 1.88283L11.1577 2.06587C11.1245 2.08959 11.0803 2.05744 11.0926 2.01858L11.1875 1.71848L10.9343 1.5315C10.9015 1.50731 10.9184 1.45528 10.9591 1.455L11.2738 1.45256L11.3734 1.15397C11.3865 1.11534 11.4412 1.11534 11.4541 1.15397Z"
          fill="#F5F5F5"
        />
        <path
          d="M11.4541 3.64473L11.5536 3.94332L11.8684 3.94576C11.9091 3.94609 11.926 3.99807 11.8933 4.0223L11.64 4.20929L11.735 4.50938C11.7472 4.54824 11.703 4.58035 11.6698 4.55668L11.4138 4.37363L11.1577 4.55668C11.1245 4.5804 11.0803 4.54824 11.0926 4.50938L11.1875 4.20929L10.9343 4.0223C10.9015 3.99812 10.9184 3.94609 10.9591 3.94576L11.2738 3.94332L11.3734 3.64473C11.3865 3.60605 11.4412 3.60605 11.4541 3.64473Z"
          fill="#F5F5F5"
        />
        <path
          d="M11.4541 6.4073L11.5536 6.70589L11.8684 6.70833C11.9091 6.70866 11.926 6.76064 11.8933 6.78488L11.64 6.97186L11.735 7.27196C11.7472 7.31082 11.703 7.34292 11.6698 7.31925L11.4138 7.13625L11.1577 7.31925C11.1245 7.34292 11.0803 7.31082 11.0926 7.27196L11.1875 6.97186L10.9343 6.78488C10.9015 6.76069 10.9184 6.70866 10.9591 6.70833L11.2738 6.70589L11.3734 6.4073C11.3865 6.36863 11.4412 6.36863 11.4541 6.4073Z"
          fill="#F5F5F5"
        />
        <path
          d="M11.4541 8.94539L11.5536 9.24398L11.8684 9.24642C11.9091 9.24674 11.926 9.29873 11.8933 9.32296L11.64 9.50995L11.735 9.81004C11.7472 9.8489 11.703 9.88101 11.6698 9.85734L11.4138 9.67429L11.1577 9.85734C11.1245 9.88101 11.0803 9.8489 11.0926 9.81004L11.1875 9.50995L10.9343 9.32296C10.9015 9.29878 10.9184 9.24674 10.9591 9.24642L11.2738 9.24398L11.3734 8.94539C11.3865 8.90671 11.4412 8.90671 11.4541 8.94539Z"
          fill="#F5F5F5"
        />
        <path
          d="M11.4541 11.255L11.5536 11.5535L11.8684 11.556C11.9091 11.5563 11.926 11.6083 11.8933 11.6325L11.64 11.8195L11.735 12.1196C11.7472 12.1585 11.703 12.1906 11.6698 12.1669L11.4138 11.9839L11.1577 12.1669C11.1245 12.1906 11.0803 12.1585 11.0926 12.1196L11.1875 11.8195L10.9343 11.6325C10.9015 11.6083 10.9184 11.5563 10.9591 11.556L11.2738 11.5535L11.3734 11.255C11.3865 11.2163 11.4412 11.2163 11.4541 11.255Z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_830_19707">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnSVG;
