import { AxiosResponse } from 'axios';
import md5 from 'blueimp-md5';
import queryString from 'query-string';

import { ChannelFeed } from '@/app/actions/online-tv/types';

import type { InfiniteLoaderData } from '@/api/types/common.types';
import type { OnlineTvList } from '@/api/types/home.types';
import type {
  ChannelsResponse,
  OnlineTvCategory,
  OnlineTvChannel,
  SelectOption,
} from '@/api/types/online-tv.types';

import { httpClient } from '@/libs/http-client';

// This config is written for new base API URL
const config = {
  baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
};

export class OnlineTvApi {
  static categoriesDataExtractor = (category: OnlineTvCategory): SelectOption => {
    return {
      value: category.id,
      label: category.title,
      count: category.count,
    };
  };

  // =================================================================

  static fetchChannels = async (params: { category_id: string; limit: number; page: number }) => {
    const { data } = await httpClient.get<ChannelsResponse>('/content/online-tv/list', {
      params,
    });

    const transformedData: InfiniteLoaderData<OnlineTvChannel, unknown> = {
      currentPage: data.current_page,
      lastPage: data.last_page,
      total: data.total,
      list: data.list,
    };

    return transformedData;
  };

  // =================================================================

  static fetchCategories = async () => {
    const { data: categories } = await httpClient.get<OnlineTvCategory[]>(
      '/content/online-tv/categories',
    );

    return categories.map(category => OnlineTvApi.categoriesDataExtractor(category));
  };

  // =================================================================

  static fetchHomeTvList = async () => {
    const { data } = await httpClient.get<OnlineTvList[]>('/home/iptvNew');

    return data;
  };

  // =================================================================

  static fetchChannelFeed = async (params: { channelId: number; token: string }) => {
    const { channelId, token } = params;

    const hash = md5(`${channelId}hls${token}${process.env.NEXT_PUBLIC_CINERAMA_HASH_SECRET}`);
    const query = queryString.stringify({ hash, channel_id: channelId });

    const response = await httpClient.get<ChannelFeed, AxiosResponse & { code?: number }>(
      `/content/online-tv/play?${query}`,
    );

    return response;
  };

  // =================================================================

  static toggleLike = ({ channelId }: { channelId: number }) => {
    const data = { channel_id: channelId };
    return httpClient.post<unknown, AxiosResponse & { code?: number }>(
      '/subscriber/favourite/channel',
      data,
      config,
    );
  };

  // =================================================================

  static toggleDislike = async ({ channelId }: { channelId: number }) => {
    return await httpClient.delete(`/subscriber/favourite/channel/${channelId}`, config);
  };
}
