'use client';

import { Fragment } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useMedia } from 'react-use';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { breakpoints } from '@/app.config';
import ImdbSVG from '@/icons/ImdbSVG';
import KinopoiskSVG from '@/icons/KinopoiskSVG';
import { ClassNameProps } from '@/types/common.types';

import { RandomMovieResponse } from '@/views/HomeView/RandomMovie/useRandomMovie';

import { formatTime } from '@/helpers/format-time.helpers';

import { CardImage } from '@/components/Card';
import { ForwardedLink } from '@/components/ForwardedLink';
import { TruncatedText } from '@/components/TruncatedText';

import {
  RandomCardActions,
  RandomCardActionsProps,
} from '@/views/HomeView/RandomMovie/RandomCardActions';
import styles from '@/views/HomeView/RandomMovie/RandomSection.module.scss';

// =================================================================

interface RandomSectionProps
  extends ClassNameProps,
    Pick<RandomCardActionsProps, 'isLoading' | 'refetch'> {
  movieDetails: RandomMovieResponse;
}

// =================================================================

const CARD_ASPECT_RATIO = 526 / 507;
const CARD_ASPECT_RATIO_FOR_MOBILE = 401 / 401;

// =================================================================

export const RandomSection = (props: RandomSectionProps) => {
  const { isLoading, refetch, movieDetails, className } = props;
  const t = useTranslations('common');

  const isLarge = useMedia(breakpoints.large, false);

  const isMobileDevice = !isLarge;

  return (
    <div className={clsx(styles.randomMovieWrapper, className)}>
      <div className={styles.movieDetails}>
        <div className={styles.movieInfo}>
          <TruncatedText as="h3" lines={isLarge ? 2 : 1} className={styles.movieTitle}>
            {isLoading ? (
              <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={160}>
                <Skeleton />
              </SkeletonTheme>
            ) : (
              <Fragment>{movieDetails?.title}</Fragment>
            )}
          </TruncatedText>

          <TruncatedText as="p" lines={2} className={styles.description}>
            {isLoading ? (
              <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={290}>
                <Skeleton count={2} />
              </SkeletonTheme>
            ) : (
              <Fragment>{movieDetails?.description}</Fragment>
            )}
          </TruncatedText>

          <ul className={styles.detailsInfoWrapper}>
            {/* Countries */}
            <li>
              <p className={styles.label}>{t('countries')}:</p>
              <div className={styles.linkBtnWrapper}>
                {isLoading ? (
                  <SkeletonTheme
                    baseColor="#0F0F12"
                    highlightColor="#47474A"
                    width={100}
                    height={22}
                  >
                    <Skeleton />
                  </SkeletonTheme>
                ) : (
                  <Fragment>
                    {movieDetails?.countries.map(country => (
                      <ForwardedLink key={country.id} href={`/country/${country.id}`}>
                        {country.title}
                      </ForwardedLink>
                    ))}
                  </Fragment>
                )}
              </div>
            </li>
            {/* Ratings */}
            <li>
              <p className={styles.label}>{t('ratings')}:</p>
              <div className={styles.ratingWrapper}>
                {isLoading ? (
                  <SkeletonTheme
                    baseColor="#0F0F12"
                    highlightColor="#47474A"
                    width={100}
                    height={22}
                  >
                    <Skeleton />
                  </SkeletonTheme>
                ) : (
                  <Fragment>
                    {movieDetails?.rating?.imdb && (
                      <div className={styles.rating}>
                        <ImdbSVG />
                        <p className={styles.infoText}>{movieDetails?.rating.imdb.rating}</p>
                      </div>
                    )}
                    {movieDetails?.rating?.kp && (
                      <div className={styles.rating}>
                        <KinopoiskSVG />
                        <p className={styles.infoText}>{movieDetails?.rating.kp.rating}</p>
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </li>
            {/* Genres */}
            <li>
              <p className={styles.label}>{t('genres')}:</p>
              <div className={styles.linkBtnWrapper}>
                {isLoading ? (
                  <SkeletonTheme
                    baseColor="#0F0F12"
                    highlightColor="#47474A"
                    width={100}
                    height={22}
                  >
                    <Skeleton />
                  </SkeletonTheme>
                ) : (
                  <Fragment>
                    {movieDetails?.genres.map(genre => (
                      <ForwardedLink
                        key={genre.id}
                        href={`/genre/${genre.id}`}
                        className={styles.linkBtn}
                      >
                        {genre.title}
                      </ForwardedLink>
                    ))}
                  </Fragment>
                )}
              </div>
            </li>
            {/* Languages */}
            {movieDetails?.languages.length > 0 && (
              <li>
                <p className={styles.label}>{t('languages')}:</p>
                <div className={styles.linkBtnWrapper}>
                  {isLoading ? (
                    <SkeletonTheme
                      baseColor="#0F0F12"
                      highlightColor="#47474A"
                      width={100}
                      height={22}
                    >
                      <Skeleton />
                    </SkeletonTheme>
                  ) : (
                    <Fragment>
                      {movieDetails?.languages.map((language, index) => (
                        <p key={index} className={clsx(styles.infoText, styles.capitalized)}>
                          {language}
                          {index !== movieDetails?.languages.length - 1 && ','}
                        </p>
                      ))}
                    </Fragment>
                  )}
                </div>
              </li>
            )}
            {/* Duration */}
            <li>
              <p className={styles.label}>{t('duration')}:</p>
              {isLoading ? (
                <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={100} height={22}>
                  <Skeleton />
                </SkeletonTheme>
              ) : (
                <p className={styles.infoText}>{formatTime(movieDetails?.duration, true)}</p>
              )}
            </li>
          </ul>
        </div>

        <RandomCardActions
          isLoading={isLoading}
          refetch={refetch}
          moduleId={movieDetails?.moduleId}
          movieId={movieDetails?.id}
          fileId={movieDetails?.files[0].id}
          className={styles.actionsButton}
        />
      </div>
      <div className={styles.movieImageWrapper}>
        {isLoading ? (
          <CardImage
            as="div"
            alt={'random-movie-poster'}
            src={'/assets/images/collection-card-placeholder.png'}
            aspectRatio={isMobileDevice ? CARD_ASPECT_RATIO_FOR_MOBILE : CARD_ASPECT_RATIO}
            className={styles.movieImage}
          />
        ) : (
          <CardImage
            as="div"
            alt={movieDetails?.title}
            src={movieDetails?.poster}
            aspectRatio={isMobileDevice ? CARD_ASPECT_RATIO_FOR_MOBILE : CARD_ASPECT_RATIO}
            className={styles.movieImage}
          />
        )}
      </div>
    </div>
  );
};
