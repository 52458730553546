'use client';

import { useController, useFormContext } from 'react-hook-form';

import cn from 'classnames';

import { ClassNameProps } from '@/types/common.types';

import styles from '@/components/Form/FormSelect.module.scss';
import { Select } from '@/components/Form/Select/Select';
import { Option, SelectProps } from '@/components/Form/Select/context/SelectProvider';

// =================================================================

type FormSelectProps = Omit<SelectProps, 'value' | 'children'> &
  ClassNameProps & {
    label?: string;
    name: string;
    errorClassName?: string;
    type?: 'dropdown' | 'modalDropdown';
  };

// =================================================================

export const FormSelect = (props: FormSelectProps) => {
  const {
    name,
    label,
    placeholder,
    initialValue,
    options,
    isDisabled,
    className,
    errorClassName,
    type = 'dropdown',
    ...rest
  } = props;

  const { control } = useFormContext();

  const {
    field: { onChange, disabled, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = (value: Option[] | Option) => {
    if (value) {
      onChange(value);
    } else {
      onChange(initialValue);
    }
  };

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={name}
          className={cn({
            [styles.label]: true,
            [styles.isDisabled]: isDisabled || disabled,
          })}
        >
          {label}
        </label>
      )}

      <Select
        name={name}
        placeholder={placeholder}
        value={value}
        options={options}
        isDisabled={isDisabled}
        onChange={handleChange}
        initialValue={initialValue}
        isClearable
        {...rest}
      >
        {type === 'dropdown' ? (
          <Select.Dropdown />
        ) : (
          <Select.ModalDropdown label={label || placeholder} />
        )}
      </Select>
      {error && <div className={cn(styles.error, errorClassName)}>{error.message}</div>}
    </div>
  );
};
