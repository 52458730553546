'use client';

import { useQueryClient } from '@tanstack/react-query';

import { useLocale, useTranslations } from 'next-intl';

import { moduleTypes } from '@/app.config';
import ChevronRightSVG from '@/icons/ChevronRightSVG';
import { useRouter } from '@/navigation';
import { Locale } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';
import { useHomeTvList } from '@/views/HomeView/OnlineTvList/useHomeTvList';

import { OnlineTvList as OnlineTvListType } from '@/api/types/home.types';

import { ForwardedLink } from '@/components/ForwardedLink';

import { HomeQueryKeys } from '@/contexts/QueryContext/query.keys';

import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';
import { OnlineTVListViewAllButton } from '@/views/HomeView/OnlineTvList/OnlineTVListViewAllButton';
import styles from '@/views/HomeView/OnlineTvList/OnlineTvList.module.scss';
import { OnlineTvCard } from '@/views/OnlineTvView/OnlineTvCard/OnlineTvCard';
import { OlineTvCardSkelton } from '@/views/OnlineTvView/OnlineTvCard/OnlineTvCardSkelton';

// =================================================================

export const OnlineTvList = () => {
  const t = useTranslations('');

  const { refetch, channels, status } = useHomeTvList();

  const router = useRouter();
  const queryClient = useQueryClient();

  const locale = useLocale() as Locale;

  const { mergeQueryParams } = useSearchParamsResult();

  const {
    checkAuthVerification,
    checkSubscriptionVerification,
    setSubscriptionTitle,
    closeVerificationModal,
    userAccessToken,
  } = useVerification();

  const callBackOnMoundFn = ({ moduleId, title = '' }: { moduleId: number; title?: string }) => {
    // TODO : this title should be sent by the backend
    const subscriptionTitle =
      moduleId === 32
        ? t('verification.subscriptionTitleSport', { channel: title })
        : t('verification.subscriptionTitle', { channel: title });
    setSubscriptionTitle(subscriptionTitle);

    mergeQueryParams({ moduleId });
  };

  const callBackOnSuccessFn = async (id: number) => {
    router.push(`/online-tv/player/?autoplay=true&channelId=${id}`);
  };

  const handleChannelClick = checkAuthVerification({
    onSuccess: async channel => {
      const { id, title, moduleId, isFree: isFreeChannel } = channel as OnlineTvListType;

      const isFree = userAccessToken
        ? isFreeChannel
        : await queryClient
            .invalidateQueries({
              queryKey: HomeQueryKeys.homeOnlineTv(locale),
            })
            .then(async () => {
              const onlineTvList = (await queryClient.getQueryData(
                HomeQueryKeys.homeOnlineTv(locale),
              )) as OnlineTvListType[];

              const channelData = onlineTvList?.find((item: OnlineTvListType) => item.id === id);
              return channelData?.isFree;
            });

      if (isFree) {
        callBackOnSuccessFn(id);

        setTimeout(() => closeVerificationModal(), 1000); // TODO: it is bad solition but it is temporary
      } else {
        checkSubscriptionVerification({
          onMountFn: () => callBackOnMoundFn({ moduleId, title }),
          onSuccess: () => callBackOnSuccessFn(id),
        });
      }
    },
  });

  return (
    <HomeContentBaseSlider
      xs={3}
      sm={3}
      md={4}
      lg={5}
      xl={6}
      xxl={7}
      heading={
        <ForwardedLink
          href={`/${moduleTypes.onlineTv}?categoryId=15`}
          className={styles.sectionHeading}
        >
          {t('page.home.onlineTv')}
          <ChevronRightSVG className={styles.icon} />
        </ForwardedLink>
      }
      className={styles.onlineTvList}
      slideClassName={styles.onlineTvListSlide}
    >
      {status === 'pending'
        ? Array(8)
            .fill(null)
            .map((_, index) => <OlineTvCardSkelton key={index} />)
        : channels.map(channel => (
            <OnlineTvCard
              key={channel.id}
              channel={channel}
              onSuccessLiked={refetch}
              handleChannelClick={handleChannelClick}
            />
          ))}
      <OnlineTVListViewAllButton href={`/${moduleTypes.onlineTv}`} />
    </HomeContentBaseSlider>
  );
};
