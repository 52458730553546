import clsx from 'clsx';

import { ClassNameProps } from '@/types/common.types';

import { Focusable } from '@/components/Focusable';
import { ModalBody } from '@/components/Modal/ModalBody';
import { ModalFooter } from '@/components/Modal/ModalFooter';
import { ModalHeader } from '@/components/Modal/ModalHeader';
import { Overlay, type OverlayProps } from '@/components/Overlay';

import styles from './Modal.module.scss';

export interface ModalProps extends OverlayProps, ClassNameProps {
  size: 'sm' | 'md' | 'lg' | 'xl';
  shouldFocusOnMount?: boolean;
  modalInnerContainerClassName?: string;
}

export const Modal = (props: ModalProps) => {
  const {
    shouldFocusOnMount,
    size,
    className,
    children,
    isOpen,
    modalInnerContainerClassName,
    ...restOfOverlayProps
  } = props;

  return (
    <Overlay isOpen={isOpen} {...restOfOverlayProps}>
      <div className={clsx(styles.modalContainer, styles[size], className)}>
        <Focusable
          shouldFocusOnMount={shouldFocusOnMount}
          className={clsx(styles.modal, modalInnerContainerClassName)}
        >
          {children}
        </Focusable>
      </div>
    </Overlay>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
