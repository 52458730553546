'use client';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useTranslations } from 'next-intl';

import ChevronRightSVG from '@/icons/ChevronRightSVG';

import { useUnfinishedMovies } from '@/views/HomeView/UnfinishedMovieList/useUnfinishedMovies';

import { ForwardedLink } from '@/components/ForwardedLink';
import { UnfinishedMovieCard, UnfinishedMovieCardSkelton } from '@/components/UnfinishedMovieCard';

import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';
import styles from '@/views/HomeView/UnfinishedMovieList/UnfinishedMovieList.module.scss';

export const UnfinishedMovieList = () => {
  const t = useTranslations('page.home');

  const { movies, isEnable, status } = useUnfinishedMovies();

  if (status === 'pending' && isEnable) {
    return (
      <HomeContentBaseSlider
        xs={2}
        sm={2}
        md={2}
        lg={3}
        xl={4}
        xxl={4}
        heading={
          <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={240}>
            <Skeleton />
          </SkeletonTheme>
        }
      >
        {Array(10)
          .fill(null)
          .map((_, index) => (
            <UnfinishedMovieCardSkelton key={index} />
          ))}
      </HomeContentBaseSlider>
    );
  }

  if (movies.length === 0 || !isEnable) {
    return null;
  }

  return (
    <HomeContentBaseSlider
      xs={2}
      sm={2}
      md={2}
      lg={3}
      xl={4}
      xxl={4}
      heading={
        <ForwardedLink href={'/users-history/history'} className={styles.sectionHeading}>
          {t('continueWatch')}
          <ChevronRightSVG className={styles.icon} />
        </ForwardedLink>
      }
    >
      {movies.map(movie => (
        <UnfinishedMovieCard key={movie.id} movie={movie} deepRemove={false} />
      ))}
    </HomeContentBaseSlider>
  );
};
