import { type MouseEvent } from 'react';

import clsx from 'clsx';

import FavoriteSVG from '@/icons/FavoriteSVG';
import FilledHeartSVG from '@/icons/FilledHeartSVG';

import {
  useToggleDislikeMutation,
  useToggleLikeMutation,
} from '@/views/OnlineTvView/useTvFavorite';

import styles from '@/views/OnlineTvView/FavoriteToggleButton/FavoriteToggleButton.module.scss';

// ===============================================================

export interface FavoriteButtonProps {
  isFavorite: boolean;
  id: number;
  onSuccessLiked?: () => void;
}

// ===============================================================

export const FavoriteToggleButton = (props: FavoriteButtonProps) => {
  const { isFavorite, id, onSuccessLiked } = props;

  const { mutate: likeMutate, status } = useToggleLikeMutation({
    onSuccess: onSuccessLiked,
  });

  const { mutate: dislikeMutate, status: statusDislike } = useToggleDislikeMutation({
    onSuccess: onSuccessLiked,
  });

  const handleFavoriteButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (status === 'pending' || statusDislike === 'pending') return;

    if (isFavorite) {
      dislikeMutate(id);
    } else {
      likeMutate(id);
    }
  };

  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={handleFavoriteButtonClick}
      className={clsx(styles.tvFavoriteButton, {
        [styles.active]: isFavorite,
        [styles.disabled]: status === 'pending' || statusDislike === 'pending',
      })}
      aria-label={isFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
      title={isFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
    >
      {isFavorite ? (
        <FilledHeartSVG width={25} height={25} />
      ) : (
        <FavoriteSVG width={25} height={25} />
      )}
    </div>
  );
};
