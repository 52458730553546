import React, { useRef, useEffect } from 'react';

// =================================================================

type RefObject<T> = React.RefObject<T>;

// =================================================================

export const useClickOutside = <T extends HTMLElement>(handler: () => void): RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        handler();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [handler]);

  return ref;
};
