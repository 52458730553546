'use client';

import { SearchBar } from '../SearchBar/SearchBar';
import { CategoryToggler } from './CategoryPanel';

import CategorySVG from '@/icons/CategorySVG';
import FilterSVG from '@/icons/FilterSVG';

import { IconButton } from '@/components/Buttons';
import { NavLink } from '@/components/NavLink';

import styles from '@/layout/DesktopMenu/SearchCategory.module.scss';

import { useStore } from '@/contexts/StoreContext';

export const SearchCategory = () => {
  const { isSearchBarVisible, toggleSearchBar } = useStore(state => state);

  return (
    <div className={styles.searchCategoryContainer}>
      <CategoryToggler />
      <SearchBar toggleSearchBar={toggleSearchBar} isSearchBarVisible={isSearchBarVisible} />
      <NavLink href="/movies/all" className={styles.categoryButton} activeClassName={styles.active}>
        <FilterSVG width={20} height={20} />
      </NavLink>
    </div>
  );
};
