'use client';

import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import clsx from 'clsx';
import { isBoolean } from 'lodash';

import CheckmarkSVG from '@/icons/CheckmarkSVG';
import ToggleActiveSVG from '@/icons/ToggleActiveSVG';
import ToggleSVG from '@/icons/ToggleSVG';

import styles from '@/components/Form/FormCheckbox.module.scss';

// =================================================================

enum CheckboxType {
  CHECKBOX = 'checkbox',
  TOGGLE = 'toggle',
  CUSTOMCHECK = 'custom-check',
}

type FormCheckboxProps = Omit<React.HtmlHTMLAttributes<HTMLInputElement>, 'onClick'> & {
  label?: React.JSX.Element | string;
  name: string;
  disabled?: boolean;
  shape?: 'circle' | 'round';
  variant?: 'toggle' | 'checkbox' | 'custom-check';
  checked?: boolean;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
};

// =================================================================

const CheckboxLabel = (props: Pick<FormCheckboxProps, 'name' | 'disabled' | 'children'>) => {
  const { name, disabled, children } = props;
  return (
    <label
      htmlFor={name}
      className={clsx(styles.label, {
        [styles.disabled]: disabled,
      })}
    >
      {children}
    </label>
  );
};

// =================================================================

export const FormCheckbox = (props: FormCheckboxProps) => {
  const {
    name,
    label,
    disabled,
    shape = 'round',
    variant = 'checkbox',
    checked,
    onChange: propsOnChange,
    className,
    ...rest
  } = props;

  const { control, setValue } = useFormContext();

  const {
    field: { value, onChange, ...fieldProps },
  } = useController({
    name,
    control,
    defaultValue: checked,
  });

  useEffect(() => {
    isBoolean(checked) && setValue(name, checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const renderCheckboxMark: Record<CheckboxType, React.JSX.Element> = {
    [CheckboxType.CHECKBOX]: (
      <CheckboxLabel name={name} disabled={disabled}>
        <span
          className={clsx(styles.checkedIconContainer, styles[shape], {
            [styles.checked]: value,
          })}
        >
          {value && <CheckmarkSVG className={styles.checkedIcon} />}
        </span>
        {label}
      </CheckboxLabel>
    ),
    [CheckboxType.TOGGLE]: (
      <CheckboxLabel name={name} disabled={disabled}>
        <span
          className={clsx(styles.toggleBar, {
            [styles.checked]: value,
          })}
        >
          {value ? <ToggleActiveSVG /> : <ToggleSVG />}
        </span>
        {label}
      </CheckboxLabel>
    ),
    [CheckboxType.CUSTOMCHECK]: (
      <CheckboxLabel name={name} disabled={disabled}>
        <span
          className={clsx(styles.tabsCheckbox, {
            [styles.checked]: checked || value,
          })}
        >
          {label}
        </span>
      </CheckboxLabel>
    ),
  };

  return (
    <div className={clsx(styles.formCheckbox, className)}>
      <input
        id={name}
        type="checkbox"
        value={value}
        checked={!!value}
        className={clsx(styles.checkbox, {
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
        onChange={event => {
          onChange(event);
          if (typeof propsOnChange === 'function') {
            propsOnChange(event);
          }
        }}
        {...fieldProps}
        {...rest}
      />

      {renderCheckboxMark[variant]}
    </div>
  );
};

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  FormCheckbox.displayName = 'FormCheckbox';
}
