'use client';

import { Fragment, useMemo, useState } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';

import ChevronDownSVG from '@/icons/ChevronDownSVG';
import { ClassNameProps } from '@/types/common.types';

import { Button } from '@/components/Buttons';
import { FormCheckbox } from '@/components/Form/FormCheckbox';
import styles from '@/components/Form/Select/ModalOptionsSelect.module.scss';
import {
  MultiValue,
  MultiValueContainer,
  SingleValue,
} from '@/components/Form/Select/SelectValues';
import { useSelectContext } from '@/components/Form/Select/context/SelectProvider';
import { List } from '@/components/List';
import { Modal } from '@/components/Modal';

// =================================================================

type ModalOptionsSelectProps = ClassNameProps & {
  label?: string;
};

// =================================================================

export const ModalOptionsSelect = (props: ModalOptionsSelectProps) => {
  const { className, label } = props;
  const {
    isMulti,
    selectedOptions = [],
    selectedOption,
    handleChange,
    isDisabled,
    placeholder,
    options,
    isActiveDropdownItem,
    isMaxSelectableLimit,
    onSubmitted,
    name,
  } = useSelectContext();

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const toggleMenuIsOpen = (): void => {
    if (isDisabled) return;
    setMenuIsOpen(value => !value);
  };

  const closeMenu = (): void => {
    setMenuIsOpen(false);
  };

  return (
    <div className={className}>
      <div onClick={toggleMenuIsOpen} className={styles.selectLabel}>
        <div className={styles.selectLabelContent}>
          <SingleValue>{placeholder}</SingleValue>
          {isMulti ? (
            <MultiValueContainer>
              <MultiValue>{selectedOptions.map(item => item.label).join(', ')}</MultiValue>
            </MultiValueContainer>
          ) : (
            <SingleValue>{selectedOption?.label}</SingleValue>
          )}
        </div>

        <ChevronDownSVG
          width={16}
          height={16}
          className={clsx(styles.arrowClassName, {
            [styles.rotate]: menuIsOpen,
          })}
        />
      </div>
      <Modal
        isOpen={menuIsOpen}
        size="sm"
        onClose={closeMenu}
        className={styles.selectModalWrapper}
      >
        <Modal.Header onClose={closeMenu} className={styles.modalHeader}>
          {label}
        </Modal.Header>
        <Modal.Body className={styles.modalContainer}>
          <List role="menu" aria-orientation="vertical" className={styles.modalOption}>
            {options.map((option, index) => (
              <List.Element
                as="li"
                key={option.value}
                value={option.value}
                activeClassName={styles.activeDropdownItem}
                className={styles.selectOption}
                isLast={index === options.length - 1}
              >
                <FormCheckbox
                  onChange={() => handleChange(option.value)}
                  label={option.label}
                  name={option.value + name}
                  shape="round"
                  className={styles.dropdownItem}
                  checked={isActiveDropdownItem(option.value)}
                  disabled={!isActiveDropdownItem(option.value) && isMaxSelectableLimit}
                />
              </List.Element>
            ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="lg"
            variant="primary"
            as="button"
            className={styles.modalButton}
            onClick={() => {
              if (typeof onSubmitted === 'function') {
                onSubmitted();
              }

              closeMenu();
            }}
            disabled={isDisabled}
          >
            Подтвердить
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
