/* eslint-disable @next/next/no-img-element */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import clsx from 'clsx';
import { isEmpty } from 'lodash';

import PremiumSVG from '@/icons/PremiumSVG';

import { TransformedSubscription } from '@/api/types/user.types';

import { SubscriptionTagsList } from '@/components/SubscriptionCards/SubscriptionTagsList';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionCard.module.scss';

// =================================================================

export type SubscriptionCardProps = {
  subscription: TransformedSubscription;
  type: 'primary' | 'secondary' | 'default';
  handleClick?: () => void;
};

// =================================================================

export const SubscriptionCard = (props: SubscriptionCardProps) => {
  const { subscription, type = 'default', handleClick } = props;

  const { title, tags, description } = subscription;

  return (
    <div className={clsx(styles.subscriptionCard, styles[type])} onClick={handleClick}>
      {type !== 'default' && (
        <div className={styles.backgroundImage}>
          <img src={`/assets/images/${type}-subscription.png`} alt={`${type}-placeholder-image`} />
        </div>
      )}

      <div className={styles.subcscriptionMainHeader}>
        <div className={styles.headerLeft}>
          {type === 'primary' && <PremiumSVG width={28} height={28} />}

          <h2 className={clsx(styles.title, styles[type])}>{title}</h2>
        </div>
      </div>

      {!isEmpty(tags) && (
        <SubscriptionTagsList
          type={type === 'primary' ? 'primary' : 'secondary'}
          tags={tags}
          className={styles.subcscriptionTagsList}
        />
      )}
      {description && (
        <p
          className={clsx(styles.description, {
            'pt-3': !isEmpty(tags),
          })}
        >
          {description}
        </p>
      )}
    </div>
  );
};

export const SubscriptionSkeletonCard = () => {
  return (
    <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A">
      <Skeleton className={styles.subscriptionCardSkelton} />
    </SkeletonTheme>
  );
};
