'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import type { ChildrenProps } from '@/types/common.types';

import { HTTP_CODE_BAD_REQUEST, HTTP_CODE_UNAUTHORIZED } from '@/constants/http-code.constants';

// =================================================================

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // optimizes re-renders when fetching data
      refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
      notifyOnChangeProps: ['isLoading', 'data'],
      // To do: check if it is working properly or not
      retry: (failureCount: number, error) => {
        const code = (error as { response?: { code?: number } })?.response?.code;

        if (code === HTTP_CODE_UNAUTHORIZED || code === HTTP_CODE_BAD_REQUEST) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

// =================================================================

export const ReactQueryProvider = (props: ChildrenProps) => {
  const { children } = props;

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools buttonPosition="top-right" />}
    </QueryClientProvider>
  );
};
