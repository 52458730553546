'use client';

import { useQuery } from '@tanstack/react-query';
import { useContext, createContext } from 'react';

import { ChildrenProps } from '@/types/common.types';

import { httpClient } from '@/libs/http-client';

// =================================================================

type NetworkState = {
  isTasix: boolean;
  ipAddress: string;
};

const NetworkContext = createContext<NetworkState | null>(null);

// =================================================================

export const NetworkProvider = (props: ChildrenProps) => {
  const { children } = props;

  const verifyNetwork = async () => {
    const { data } = await httpClient.get<{ tasix: boolean; ip: string }>('/network/tas-ix');

    return data;
  };

  const { data: { tasix, ip } = { tasix: false, ip: '' } } = useQuery({
    queryKey: ['network-info'],
    queryFn: verifyNetwork,
    staleTime: Infinity,
  });

  return (
    <NetworkContext.Provider value={{ isTasix: tasix, ipAddress: ip }}>
      {children}
    </NetworkContext.Provider>
  );
};

// =================================================================

export const useNetwork = () => {
  const context = useContext(NetworkContext);

  if (!context) {
    throw new Error('useNetwork should be used within NetworkProvider');
  }

  return context;
};
