import { Fragment, useRef, useState } from 'react';

import GoogleSVG from '@/icons/GoogleLogoSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { IconButton } from '@/components/Buttons/IconButton';
import { SessionsModal } from '@/components/SessionsModal';

import { useNotification } from '@/contexts/NotificationContext';
import { GoogleLoginBase } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/SocialLoginPanel/GoogleLoginButton/GoogleLoginBase';
import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/SocialLoginPanel/GoogleLoginButton/GoogleLoginButton.module.scss';

export const GoogleLoginButton = () => {
  const { authCallbackOnSuccess, setAccessToken, setUserCredentials } = useVerification();
  const { notification } = useNotification();

  const [isGooleAuthApplicable, setIsGooleAuthApplicable] = useState(true);
  const [sessions, setSessions] = useState([]);
  const closeModal = () => setSessions([]);

  const userDataRef = useRef(null);

  const handleGoogleLogin = async userData => {
    try {
      const params = {
        social_id: userData.id,
        full_name: userData.name,
        email: userData.email,
        social_type: 'google',
      };

      const response = await UserApi.signInWithSocialNetwork(params);
      const haveSessions = response?.data?.sessionList?.length > 0;

      if (haveSessions) {
        const { sessionList = [] } = response.data;
        setSessions(sessionList);
        notification('error', response?.message);
      } else {
        const { cineramaToken, me } = response.data;
        setAccessToken(cineramaToken);
        setUserCredentials(me);
        authCallbackOnSuccess();
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      notification('error', error?.response?.message);
    }
  };

  const handleGoogleResponse = response => {
    const { googleId, name, email } = response.profileObj;
    const userData = { id: googleId, name, email, socialProvider: 'google' };
    userDataRef.current = userData;
    handleGoogleLogin(userData);
  };

  const handleFailure = response => {
    if (response.error === 'idpiframe_initialization_failed') {
      setIsGooleAuthApplicable(false);
    }
  };

  return (
    <Fragment>
      <GoogleLoginBase
        clientId={process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID}
        prompt="select_account"
        onSuccess={handleGoogleResponse}
        onFailure={handleFailure}
        autoLoad={false}
        render={buttonProps => (
          <IconButton
            as="button"
            title="Google"
            iconWidth={20}
            iconHeight={20}
            icon={GoogleSVG}
            disabled={buttonProps.disabled || !isGooleAuthApplicable}
            onClick={buttonProps.onClick}
            className={styles.googleButton}
            iconClassName={styles.googleIcon}
          />
        )}
      />
      <SessionsModal
        sessions={sessions}
        onClose={closeModal}
        onSessionDelete={() => {
          closeModal();
          handleGoogleLogin(userDataRef.current);
        }}
      />
    </Fragment>
  );
};
