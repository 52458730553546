'use client';

import clsx from 'clsx';

import { ClassNameProps } from '@/types/common.types';

import { RandomMovieResponse } from '@/views/HomeView/RandomMovie/useRandomMovie';

import { CardImage } from '@/components/Card';
import { TruncatedText } from '@/components/TruncatedText';

import styles from '@/views/HomeView/RandomMovie/MobileRandomSection.module.scss';
import {
  RandomCardActions,
  RandomCardActionsProps,
} from '@/views/HomeView/RandomMovie/RandomCardActions';

// =================================================================

interface MobileRandomSectionProps
  extends ClassNameProps,
    Pick<RandomCardActionsProps, 'isLoading' | 'refetch'> {
  movieDetails: RandomMovieResponse;
}

// =================================================================

const CARD_ASPECT_RATIO = 408 / 443;

// =================================================================

export const MobileRandomSection = (props: MobileRandomSectionProps) => {
  const { movieDetails, isLoading, refetch, className } = props;

  return (
    <div className={clsx(styles.mobileRandomMovieWrapper, className)}>
      <CardImage
        as="div"
        alt={movieDetails?.title}
        src={movieDetails?.poster}
        aspectRatio={CARD_ASPECT_RATIO}
        className={styles.movieImage}
      >
        <div className={styles.movieDetailsMobile}>
          <TruncatedText as="h3" lines={2} className={styles.movieTitle}>
            {movieDetails?.title}
          </TruncatedText>

          <RandomCardActions
            isLoading={isLoading}
            refetch={refetch}
            moduleId={movieDetails?.moduleId}
            movieId={movieDetails?.id}
            fileId={movieDetails?.files[0].id}
          />
        </div>
      </CardImage>
    </div>
  );
};
