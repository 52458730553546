import { UnfinishedMovie } from '../types/home.types';
import { AxiosResponse } from 'axios';
import md5 from 'blueimp-md5';
import queryString from 'query-string';

import { EditForm } from '@/app/actions/account/types';
import type {
  User,
  SessionList,
  SignInResponse,
  SessionsReachedResponse,
  SignInWithSocialNetworkParams,
} from '@/app/actions/auth/types';
import { RemoveAutoRenewSubscriptionResult } from '@/app/actions/subscriptions/types';
import type { Locale } from '@/types/common.types';

import { InfiniteLoaderData, PaginationParams } from '@/api/types/common.types';
import { MovieList, MovieListResponse, UnfinishedMovieListResponse } from '@/api/types/movie.types';
import {
  AutoRenewalSubscriptions,
  HistorySubscription,
  HistorySubscriptionsResponse,
  TransformedSubscription,
  UserActiveSubscription,
  UsersHistoryCount,
  UserSubscription,
} from '@/api/types/user.types';

import { getRemainingDays, normalizePrise } from '@/helpers/transformations.helpers';

import { httpClient } from '@/libs/http-client';

import { HTTP_CODE_SUCCESS } from '@/constants/http-code.constants';

// =================================================================

// This config is written for new base API URL
const config = {
  baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
};

// =================================================================

export class UserApi {
  // =================================================================

  static transformActiveSubscriptionData = (subscription: UserActiveSubscription[]) => {
    return subscription.map(subscription => {
      return {
        id: subscription.id,
        title: subscription.title,
        validUntil: subscription.validUntil,
        daysLeft: getRemainingDays(subscription.expire),
      };
    });
  };

  // =================================================================

  static transformToSrcArray = (sourcesObject: UserSubscription['icons']) => {
    return (
      sourcesObject &&
      Object.entries(sourcesObject)?.map(([key, value]) => ({
        src: value,
        alt: key,
      }))
    );
  };

  // =================================================================

  static subscriptionDataExtractor = (subscription: UserSubscription): TransformedSubscription => {
    return {
      ...subscription,
      priceStr: normalizePrise(subscription.priceStr) as string,
      isMain: subscription.is_main,
      isBuyable: subscription.is_buyable,
      oldPrice: normalizePrise(subscription.oldPrice) as string,
      discountExpire: subscription.discount_expire,
      icons: UserApi.transformToSrcArray(subscription?.icons) as any,
      forcePayWithCard: subscription?.force_pay_with_card,
    };
  };

  // =================================================================

  static transformSubscriptionsData = (data: UserSubscription[]) => {
    const subscriptions = data
      .filter(subscription => !subscription.is_main)
      .map(subscription => UserApi.subscriptionDataExtractor(subscription));

    const mainSubscriptions = data
      .filter(subscription => subscription.is_main)
      .map(subscription => UserApi.subscriptionDataExtractor(subscription));

    return { subscriptions, mainSubscriptions };
  };

  // =================================================================

  static fetchActiveSubscriptions = async () => {
    const { data } = await httpClient.get<UserActiveSubscription[]>('/subscriber/tariff/active');

    return UserApi.transformActiveSubscriptionData(data);
  };

  // =================================================================

  static fetchAutoRenewSubscriptions = async (params: { page?: number } = {}) => {
    const { data } = await httpClient.get<AutoRenewalSubscriptions[]>('/subscriber/auto-renew', {
      baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
      params,
      validateStatus: status => status === HTTP_CODE_SUCCESS,
    });

    return data;
  };

  // =================================================================

  static fetchHistorySubscriptions = async (params: Omit<PaginationParams, 'limit'>) => {
    const { data } = await httpClient.get<HistorySubscriptionsResponse>(
      '/subscriber/tariff/history',
      {
        params,
      },
    );

    const transformedData: InfiniteLoaderData<HistorySubscription, unknown> = {
      currentPage: data.currentPage,
      lastPage: data.lastPage,
      total: data.total,
      list: data.histories,
    };

    return transformedData;
  };

  // =================================================================

  static fetchFavorites = async (params: Omit<PaginationParams, 'limit'>) => {
    const { data } = await httpClient.get<MovieListResponse>('/content/movie/favourite/list', {
      params,
    });

    const transformedData: InfiniteLoaderData<MovieList> = {
      currentPage: data.currentPage,
      lastPage: data.lastPage,
      total: data.total,
      list: data.movies,
    };

    return transformedData;
  };

  // =================================================================

  static fetchHistory = async (params: Omit<PaginationParams, 'limit'>) => {
    const { data } = await httpClient.get<UnfinishedMovieListResponse>('/home/history-list', {
      params,
    });

    const transformedData: InfiniteLoaderData<UnfinishedMovie, unknown> = {
      currentPage: data.currentPage,
      lastPage: data.lastPage,
      total: data.total,
      list: data.list,
    };

    return transformedData;
  };

  // =================================================================

  static fetchSubscriptions = async (params: { module_id?: string; locale?: Locale } = {}) => {
    const { module_id, locale } = params;

    const { data } = await httpClient.get<UserSubscription[]>('/tariff/list', {
      baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_2,
      params: { module_id },
      headers: {
        Locale: locale,
      },
    });

    return data;
  };

  // =================================================================

  static fetchSessionsList = async () => {
    const { data } = await httpClient.get<SessionList[]>('/auth/session/list');

    return data;
  };

  // =================================================================

  static getMe = async () => {
    const { data } = await httpClient.get<Omit<User, 'fullName'> & { full_name: string }>(
      '/auth/get-me',
    );

    return {
      ...data,
      fullName: data.full_name,
    };
  };

  // =================================================================

  static logout = async () => {
    return await httpClient.get('/auth/logout');
  };

  // =================================================================

  static clearHistory = () => httpClient.get('/subscriber/history/clear');

  // =================================================================

  static clearFavourite = () => httpClient.post('/content/movie/favourite/clear');

  // =================================================================

  static removeHistoryItem = ({ movieId }: { movieId: number }) => {
    return httpClient.delete(`/subscriber/last-position/${movieId}`, config);
  };

  // =================================================================

  static removeDeepHistoryItem = ({ moduleId, movieId }: { movieId: number; moduleId: number }) => {
    const params = queryString.stringify({
      module_id: moduleId,
      movie_id: movieId,
    });

    return httpClient.get(`/subscriber/history/remove?${params}`);
  };

  // =================================================================

  static checkIsFavorite = async ({ moduleId, movieId }: { movieId: number; moduleId: number }) => {
    const params = queryString.stringify({
      module_id: moduleId,
      movie_id: movieId,
    });

    return httpClient.get<{ is_favourite: boolean }>(`/content/movie/favourite/check?${params}`);
  };

  // =================================================================

  static terminateSessions = async () => {
    return httpClient.get<unknown, AxiosResponse & { code?: number }>('/auth/session/terminate');
  };

  // =================================================================

  static deleteSession = ({ sessionId }: { sessionId: string }) =>
    httpClient.get<unknown, AxiosResponse & { code?: number }>(
      `/auth/session/destroy?session_id=${sessionId}`,
    );

  // =================================================================

  static deleteCard = async (cardId: number) => {
    return await httpClient.post<unknown, AxiosResponse & { code: number; message: string }>(
      '/uzcard/card/delete',
      { id: cardId },
      {
        baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_2,
      },
    );
  };
  // =================================================================

  static addBankCard = async (params: {
    card_number: string;
    card_exp: string;
    user_phone: string;
  }) => {
    return await httpClient.post<unknown, { message: string }>('/uzcard/card/add', params, {
      baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_2,
    });
  };

  // =================================================================

  static cardConfrimOTP = async (otp_session: string) => {
    return await httpClient.post<unknown, AxiosResponse & { code: number; message: string }>(
      '/uzcard/card/confirm',
      { otp_session },
      {
        baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_2,
      },
    );
  };

  // =================================================================

  static editUserInfo = async (data: EditForm) => {
    return await httpClient.post<unknown, AxiosResponse & { code: number; message: string }>(
      '/auth/edit-me',
      {
        ...data,
        gender: data.gender.value,
      },
    );
  };

  // =================================================================

  static addPromoCode = async (code: string) => {
    return await httpClient.post<unknown, AxiosResponse & { code: number; message: string }>(
      '/promo-code/connect',
      { code },
      {
        baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_2,
      },
    );
  };

  // =================================================================

  static cardPayment = async (tariffId: number, isRenew: boolean) => {
    return await httpClient.post<{ message: string }>(
      '/uzcard/payment/pay',
      { tariff_id: tariffId, is_renew: Number(isRenew) },
      {
        baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_2,
      },
    );
  };

  // =================================================================

  static purchaseSubscription = async (optionId: number, isRenew: boolean) => {
    return await httpClient.post(
      `/subscriber/tariff/buy?condition_id=${optionId}&is_renew=${Number(isRenew)}`,
      {
        baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
      },
    );
  };

  // =================================================================

  static removeAutoRenewSubscriptions = async ({ subscriptionId }: { subscriptionId: number }) => {
    return await httpClient.delete<RemoveAutoRenewSubscriptionResult>(
      `/subscriber/auto-renew/${subscriptionId}`,
      {
        baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
      },
    );
  };

  // =================================================================

  static usersHistoryCount = async () => {
    const { data } = await httpClient.get<UsersHistoryCount>('/subscriber/profile/detail', {
      baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
    });

    return data;
  };

  // =================================================================

  static signIn = async (params: {
    username: string;
    password: string;
    shouldRemember?: boolean;
  }) => {
    const { username, password } = params;

    const data = { username, password };

    return await httpClient.post<
      SignInResponse | SessionsReachedResponse,
      AxiosResponse & { code: number; message: string }
    >('/auth/login', data);
  };

  // =================================================================

  static confirmOtp = async (params: { code: string; phone: string }) => {
    const { code, phone } = params;

    const normalizedPhone = phone.replace(/\s/g, '');

    const data = { code, phone: normalizedPhone };

    return await httpClient.post<
      SignInResponse | SessionsReachedResponse,
      AxiosResponse & { code: number; message: string }
    >('/auth/telephone/confirm', data);
  };

  // =================================================================

  static loginWithPhone = async (phone: string) => {
    const normalizedPhone = phone.replace(/\s/g, '');

    const time = Math.floor(Date.now() / 1000);
    const hash = md5(`${normalizedPhone}/${time}/${process.env.NEXT_PUBLIC_CINERAMA_HASH_SECRET}`);

    const params = queryString.stringify({ time, hash });

    const data = { phone: normalizedPhone };

    return await httpClient.post<
      SignInResponse | SessionsReachedResponse,
      AxiosResponse & { code: number; message: string }
    >(`/auth/telephone/code?${params}`, data);
  };

  // =================================================================

  static signInWithSocialNetwork = async (params: SignInWithSocialNetworkParams) => {
    return await httpClient.post<
      SignInResponse | SessionsReachedResponse,
      AxiosResponse & { code: number; message: string }
    >('/auth/social/login', params);
  };
}
