'use client';

import React, { Children, isValidElement } from 'react';

import clsx from 'clsx';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { NavLink } from '@/components/NavLink';
import styles from '@/components/Tabs/Tabs.module.scss';
import { TabsSwitcher } from '@/components/Tabs/TabsSwitcher';
import { useTabsContext } from '@/components/Tabs/context/TabsContext';

// =================================================================

export const TabsSwitchers = (props: ChildrenProps & Partial<ClassNameProps>) => {
  const { children, className } = props;
  const {
    variant = 'primary',
    switchersClassName,
    switcherActiveClassName,
    isRoutesBased,
    activeIndex,
    setActiveIndex,
  } = useTabsContext();

  const modifiedChildren = Children.map(children, (child, index) => {
    if (isValidElement(child) && child.type === TabsSwitcher) {
      let extraProps: Record<string, unknown>;

      if (isRoutesBased) {
        extraProps = {
          as: NavLink,
          activeClassName: clsx({
            switcherActiveClassName: Boolean(switcherActiveClassName),
            [styles.active]: true,
          }),
          isActive: activeIndex === index,
        };
      } else {
        extraProps = {
          isActive: activeIndex === index,
          as: 'button',
          onClick: () => {
            setActiveIndex && setActiveIndex(index);
          },
        };
      }

      return React.cloneElement(child, extraProps);
    }

    return child;
  });

  return (
    <div className={clsx(styles.tabsSwitchersWrapper, className)}>
      <ul className={clsx(styles.tabsSwitchers, switchersClassName, styles[variant])}>
        {modifiedChildren}
      </ul>
    </div>
  );
};
