'use client';

import { useSearchParams } from 'next/navigation';
import queryString from 'query-string';

import { useRouter, usePathname } from '@/navigation';

interface QueryParams {
  [key: string]: string | number | boolean | (string | number)[] | undefined;
}

export const useSearchParamsResult = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const query: QueryParams = {};
  searchParams.forEach((value, key) => {
    if (query[key]) {
      if (Array.isArray(query[key])) {
        (query[key] as (string | number)[]).push(value);
      } else {
        query[key] = [query[key] as string | number, value];
      }
    } else {
      query[key] = value;
    }
  });

  const queryParams = query;

  const stringify = (obj: QueryParams, params?: queryString.StringifyOptions) =>
    queryString.stringify(obj, params);

  const addQueryParams = (params: QueryParams = {}) => {
    const newQuery = queryString.stringify({ ...params });
    router.push(`${pathname}?${newQuery}`);
  };

  const mergeQueryParams = (params: QueryParams = {}, replace = false) => {
    // Merge and remove falsy values (false or undefined)
    const filteredParams = { ...queryParams, ...params };
    Object.keys(filteredParams).forEach(key => {
      if (
        filteredParams[key] === false ||
        filteredParams[key] === undefined ||
        filteredParams[key] === ''
      ) {
        delete filteredParams[key];
      }
    });

    const newQuery = queryString.stringify(filteredParams);
    if (replace) {
      router.replace(`${pathname}?${newQuery}`);
    } else {
      router.push(`${pathname}?${newQuery}`);
    }
  };

  const clearQueryParams = () => {
    router.push(pathname);
  };

  return { queryParams, addQueryParams, clearQueryParams, mergeQueryParams, stringify, router };
};
