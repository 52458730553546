'use client';

import { useMemo, useState } from 'react';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { ClassNameProps } from '@/types/common.types';

import { Button } from '@/components/Buttons';
import { Spinner } from '@/components/Spinner';

import { useNotification } from '@/contexts/NotificationContext';

import styles from '@/views/HomeView/RandomMovie/RandomCardActions.module.scss';

// =================================================================

export interface RandomCardActionsProps extends ClassNameProps {
  moduleId: number;
  movieId: number;
  seasonId?: number;
  fileId?: number;
  isLoading: boolean;
  refetch: () => void;
}

// =================================================================

export const RandomCardActions = (props: RandomCardActionsProps) => {
  const { moduleId, movieId, seasonId, fileId, refetch, className } = props;
  const t = useTranslations('common');

  const { notification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const generateHref = useMemo(() => {
    let baseHref = `/watch/${moduleId}/${movieId}`;

    if (fileId) baseHref += `?fileId=${fileId}`;
    if (seasonId) baseHref += `&seasonId=${seasonId}`;
    baseHref += '&autoplay=true';

    return baseHref;
  }, [moduleId, movieId, seasonId, fileId]);

  const refetchFunction = async () => {
    setIsLoading(true);
    try {
      await refetch();
    } catch (error) {
      setIsLoading(false);
      if (!error.response) {
        throw error;
      }
      notification('error', error?.response?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={clsx(styles.actionsButton, className)}>
      <Button
        as="link"
        href={generateHref}
        variant="primary"
        size="lg"
        className="d-flex flex-grow-1"
        disabled={isLoading}
      >
        {t('watch')}
      </Button>
      <Button
        as="button"
        variant="secondary"
        size="lg"
        onClick={() => refetchFunction()}
        disabled={isLoading}
        className={styles.randomButton}
      >
        <div className={styles.randomButtonContent}>
          {isLoading && <Spinner className={styles.spinner} />}
          {t('random')}
        </div>
      </Button>
    </div>
  );
};
