'use client';

import { createContext, useEffect } from 'react';
import { useUpdateEffect, useEffectOnce } from 'react-use';

import { useSearchParams } from 'next/navigation';

import type { User } from '@/app/actions/auth/types';
import { useRouter, usePathname } from '@/navigation';
import type { ChildrenProps } from '@/types/common.types';

import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';

import { UserApi } from '@/api/domains/user-api';
import { ErrorResponse } from '@/api/types/common.types';

import { customLocalStorage } from '@/helpers/storage.helpers';

import { httpClient } from '@/libs/http-client';

import { SubscriptionState } from '@/zustand-store/slices/subscription-slice';

import { HTTP_CODE_UNAUTHORIZED } from '@/constants/http-code.constants';
import { USER_ACCESS_TOKEN } from '@/constants/user-credentials.constants';

import { useStore } from '@/contexts/StoreContext';
import { VerificationModal } from '@/contexts/VerificationContext/VerificationModal';

// =================================================================

type VerificationState = Pick<
  SubscriptionState,
  'setSubscription' | 'setSelectToBuySubscription' | 'selectToBuySubscription' | 'subscription'
> & {
  hasOtp: boolean;
  isAcceptedPolicy: boolean;
  phone: string;
  subscriptionTitle: string;
  authCallbackOnSuccess: (...args: any[]) => void;
  subscriptionCallbackOnSuccess: (...args: any[]) => void;
  isRenew: boolean;
  userCredentials: User | null;
  userAccessToken: string | null;
  isInitialCheckDone: boolean;
  isQRCodeVerification: boolean;

  setRenew: (isRenew: boolean) => void;
  checkAuthVerification: <T extends unknown>(options?: {
    onSuccess?: (args?: T) => void | Promise<void>;
    onMountFn?: () => void;
  }) => (args?: T) => void | Promise<void>;
  checkSubscriptionVerification: (options?: {
    onSuccess?: () => void | Promise<void>;
    onMountFn?: () => void;
  }) => void;
  closeVerificationModal: () => void;
  onShallowCloseVerificationModal: () => void;
  authVerify: () => void;
  loginWithUsername: () => void;
  acceptPolicy: (status: boolean) => void;
  setPhone: (phone: string) => void;
  setOtpStatus: () => void;
  loginWithPhone: () => void;
  setSubscriptionTitle: (title: string) => void;
  setAccessToken: (token: string) => void;
  setUserCredentials: (credentials: User | null) => void;
  signOut: () => void;
  setIsInitialCheckDone: (isInitialCheckDone: boolean) => void;
  setUserAccessToken: (token: string | null) => void;
  setIsQRCodeVerification: (isQRCodeVerification: boolean) => void;
};

// =================================================================

export const VerificationContext = createContext<VerificationState | null>(null);

// =================================================================

export const VerificationProvider = (props: ChildrenProps) => {
  const { children } = props;

  const {
    isAuthVerification,
    isAcceptedPolicy,
    isSubscriptionVerification,
    phone,
    hasOtp,
    authCallbackOnSuccess,
    subscriptionCallbackOnSuccess,
    subscriptionTitle,
    isRenew,
    userAccessToken,
    userCredentials,
    isInitialCheckDone,
    isQRCodeVerification,

    setRenew,
    onCloseVerificationModal,
    onShallowCloseVerificationModal,
    authVerify,
    loginWithUsername,
    loginWithPhone,
    subscriptionVerify,
    setPhone,
    acceptPolicy,
    setOtpStatus,
    setAuthVerificationOnSuccess,
    setSubscriptionVerificationOnSuccess,
    setSubscriptionTitle,
    setUserAccessToken,
    setUserCredentials,
    setIsInitialCheckDone,
    setIsQRCodeVerification,
  } = useStore(store => store.verificationStore);

  const { setSubscription, subscription, selectToBuySubscription, setSelectToBuySubscription } =
    useStore(store => store.subscriptionStore);

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // ----------------------------------------------------------------

  const setAccessToken = (token: string) => {
    httpClient.defaults.headers['Cinerama-Token'] = token;
    setUserAccessToken(token);
    customLocalStorage.setItem(USER_ACCESS_TOKEN, token);
  };

  // ----------------------------------------------------------------

  // Check user access token validation
  useEffectOnce(() => {
    const fetchUserCredentials = async () => {
      try {
        const persistedToken = customLocalStorage.getItem(USER_ACCESS_TOKEN) as string;
        if (persistedToken) {
          httpClient.defaults.headers['Cinerama-Token'] = persistedToken;

          const userData = await UserApi.getMe();
          setAccessToken(persistedToken as string);
          setUserCredentials(userData);
        }
      } catch (error) {
        if (!error.response) {
          throw error;
        }

        const { code } = error.response as ErrorResponse;
        if (code === HTTP_CODE_UNAUTHORIZED) {
          delete httpClient.defaults.headers['Cinerama-Token'];
          customLocalStorage.removeItem(USER_ACCESS_TOKEN);
          setUserAccessToken(null);
          setUserCredentials(null);
        }
      }
    };

    fetchUserCredentials();
    setIsInitialCheckDone(true);
  });

  // ----------------------------------------------------------------

  // open the verification modal when redirected to the home page if user was unauthorized yet
  const verificationQuery = searchParams.get('verification');
  const { clearQueryParams } = useSearchParamsResult();

  useEffect(() => {
    const accessToken = customLocalStorage.getItem(USER_ACCESS_TOKEN);

    if (verificationQuery && JSON.parse(verificationQuery) === true && !accessToken) {
      setAuthVerificationOnSuccess(() => {
        clearQueryParams();
      });
      authVerify();
    } else {
      onCloseVerificationModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationQuery]);

  // ----------------------------------------------------------------

  // redirect to protected routes when user is authenticated successfully
  useUpdateEffect(() => {
    const accessToken = customLocalStorage.getItem(USER_ACCESS_TOKEN);

    const isVerificationModalClosed = !isSubscriptionVerification && !isAuthVerification;

    if (isVerificationModalClosed && accessToken) {
      const protectedRoute = searchParams.get('protectedRoute');
      const moduleId = searchParams.get('moduleId');

      const currentSearchParams = new URLSearchParams(Array.from(searchParams.entries()));
      currentSearchParams.delete('verification');
      if (protectedRoute) {
        currentSearchParams.delete('protectedRoute');
      }
      if (moduleId) {
        currentSearchParams.delete('moduleId');
      }

      const query = currentSearchParams.toString();

      const path = accessToken && protectedRoute ? protectedRoute : pathname;

      router.replace(`${path}?${query}`, { scroll: false });
    }
  }, [isAuthVerification, isSubscriptionVerification]);

  // ----------------------------------------------------------------

  const closeVerificationModal = () => {
    onCloseVerificationModal();

    if (selectToBuySubscription) {
      setSelectToBuySubscription(null);
    }
  };

  // ----------------------------------------------------------------

  const checkAuthVerification = <T extends unknown>(
    options: {
      onSuccess?: (args?: T) => void | Promise<void>;
      onMountFn?: () => void;
    } = {},
  ) => {
    const { onSuccess, onMountFn } = options;

    const accessToken = customLocalStorage.getItem(USER_ACCESS_TOKEN);

    if (typeof onMountFn === 'function') {
      onMountFn();
    }

    const callback = (args?: T) => {
      if (typeof onSuccess === 'function') {
        setAuthVerificationOnSuccess(() => onSuccess(args));
      }

      if (!accessToken) {
        authVerify();
        return;
      }

      if (typeof onSuccess === 'function') {
        return onSuccess(args);
      }
    };

    return callback;
  };

  // ----------------------------------------------------------------

  const checkSubscriptionVerification = (
    options: { onSuccess?: () => void | Promise<void>; onMountFn?: () => void } = {},
  ) => {
    const { onSuccess, onMountFn } = options;

    if (typeof onMountFn === 'function') {
      onMountFn();
    }

    subscriptionVerify();

    setSubscriptionVerificationOnSuccess(() => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      closeVerificationModal();
    });
  };

  // ----------------------------------------------------------------

  const signOut = async () => {
    try {
      await UserApi.logout();
      delete httpClient.defaults.headers['Cinerama-Token'];
      setUserAccessToken(null);
      setUserCredentials(null);
      customLocalStorage.removeItem(USER_ACCESS_TOKEN);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
    }
  };

  // ----------------------------------------------------------------

  return (
    <VerificationContext.Provider
      value={{
        hasOtp,
        isAcceptedPolicy,
        phone,
        authCallbackOnSuccess,
        subscriptionCallbackOnSuccess,
        subscriptionTitle,
        isRenew,
        setSubscription,
        subscription,
        selectToBuySubscription,
        userAccessToken,
        userCredentials,
        isInitialCheckDone,
        isQRCodeVerification,

        setSelectToBuySubscription,
        setRenew,
        checkAuthVerification,
        checkSubscriptionVerification,
        closeVerificationModal,
        onShallowCloseVerificationModal,
        acceptPolicy,
        authVerify,
        loginWithUsername,
        setPhone,
        setOtpStatus,
        loginWithPhone,
        setSubscriptionTitle,
        setAccessToken,
        setUserAccessToken,
        setUserCredentials,
        signOut,
        setIsInitialCheckDone,
        setIsQRCodeVerification,
      }}
    >
      {children}

      <VerificationModal
        size={isAuthVerification ? 'lg' : 'sm'}
        isOpen={isAuthVerification || isSubscriptionVerification}
        onClose={closeVerificationModal}
        isAuthVerification={isAuthVerification}
        isSubscriptionVerification={isSubscriptionVerification}
        userCredentials={userCredentials}
      />
    </VerificationContext.Provider>
  );
};
