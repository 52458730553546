'use client';

import { memo, Fragment } from 'react';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { breakpoints } from '@/app.config';
import AmediatekaSVG from '@/icons/AmediatekaSVG';
import MegogoSVG from '@/icons/MegogoSVG';
import StartTvSVG from '@/icons/StartTvSVG';
import WinkSVG from '@/icons/WinkSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useFavorite } from '@/hooks/useFavorite';

import { MovieCardTypeEnum } from '@/enums/movie-card-type.enums';

import { FadeTransition } from '@/components/Animations';
import { Badge } from '@/components/Badge';
import { CardImage, CardOverlay, CardSubtitle } from '@/components/Card';
import { CardRating } from '@/components/Card/CardRating';
import { MovieCardProps } from '@/components/MovieCard';
import styles from '@/components/MovieCard/MovieCard.module.scss';
import { MovieDownloadButton } from '@/components/MovieCard/MovieDownloadButton';
import { MovieFavoriteToggle } from '@/components/MovieCard/MovieFavoriteToggle';
import { MovieRemoveButton } from '@/components/MovieCard/MovieRemoveButton';

// =================================================================

const CARD_ASPECT_RATIO = 181 / 272;

// =================================================================

interface MovieCardImageContainerProps
  extends Pick<MovieCardProps, 'isFavorite' | 'isRemovable' | 'onToggled' | 'onRemoved'> {
  isSerial: boolean;
  poster: string;
  title: string;
  moduleId: number;
  movieId: number;
  cardType: MovieCardTypeEnum;
  ageLimit: number;
  isFree: boolean;
  isNew: boolean;
  isDownload: boolean;
  isHovered: boolean;
  subtitle: string;
  rating?: number;
}

// =================================================================

export const MovieCardImageContainer = memo((props: MovieCardImageContainerProps) => {
  const {
    isRemovable,
    isSerial,
    poster,
    title,
    moduleId,
    movieId,
    cardType,
    ageLimit,
    isFree,
    isNew,
    onToggled,
    onRemoved,
    isDownload = true,
    subtitle,
    isFavorite: isInitiallyFavorite,
    isHovered,
  } = props;

  const t = useTranslations('common');

  const { status, isFavorite, toggle, debouncedFetchFavoriteStatus, debouncedSetLoading } =
    useFavorite({ moduleId: moduleId, movieId, isFavorite: isInitiallyFavorite });

  const { userAccessToken } = useVerification();
  const isAuthenticated = Boolean(userAccessToken);

  return (
    <div className={styles.imageContainer}>
      {isSerial && (
        <Fragment>
          <span
            style={{ backgroundImage: `url(${poster})` }}
            className={clsx({
              [styles.stackImage]: true,
              [styles.stackImageRear]: true,
            })}
          />
          <span
            style={{ backgroundImage: `url(${poster})` }}
            className={clsx({
              [styles.stackImage]: true,
              [styles.stackImageMiddle]: true,
            })}
          />
        </Fragment>
      )}
      <CardImage
        as="link"
        href={`/watch/${moduleId}/${movieId}`}
        src={poster}
        alt={title}
        aspectRatio={CARD_ASPECT_RATIO}
        sizes={`50vw, ${breakpoints.small} 33vw, ${breakpoints.medium} 25vw, ${breakpoints.large} 17vw`}
        imageClassName={styles.movieImage}
        className={styles.movieImage}
        imageWrapperClassName={styles.movieImage}
      >
        {isFree && (
          <ul className={styles.freeBadgeWrapper}>
            <Badge as="li" variant="free" position="left">
              {t('free')}
            </Badge>
          </ul>
        )}
        {isNew && (
          <ul className={styles.newBadgeWrapper}>
            <Badge as="li" variant="new" position="left">
              {t('new')}
            </Badge>
          </ul>
        )}
        {cardType === MovieCardTypeEnum.AMEDIATIKA && (
          <span className={styles.contentOriginBadgeContainer}>
            <AmediatekaSVG />
          </span>
        )}
        <span className={styles.contentOriginBardgeMoreTv}>
          {cardType === MovieCardTypeEnum.MEGOGO && <MegogoSVG />}
          {cardType === MovieCardTypeEnum.MORETV && <WinkSVG width={70} height={30} />}
          {cardType === MovieCardTypeEnum.START && <StartTvSVG width={70} height={30} />}
        </span>
        {ageLimit > 0 && <span className={styles.ageLimit}>{ageLimit}+</span>}
        <FadeTransition inProp={isHovered}>
          <CardOverlay className={styles.movieCardOverlay}>
            <div className={styles.cardBody}>
              {isAuthenticated && (
                <div className={styles.cardBodyTop}>
                  <MovieFavoriteToggle
                    isHovered={isHovered}
                    isFavorite={isFavorite}
                    isInitiallyFavorite={Boolean(isInitiallyFavorite)}
                    debouncedFetchFavoriteStatus={debouncedFetchFavoriteStatus}
                    debouncedSetLoading={debouncedSetLoading}
                    status={status}
                    toggle={toggle}
                    onToggled={onToggled}
                  />
                  {Boolean(isDownload) && (
                    <MovieDownloadButton moduleId={moduleId} movieId={movieId} />
                  )}
                  {isRemovable && (
                    <MovieRemoveButton
                      moduleId={moduleId}
                      movieId={movieId}
                      onRemoved={onRemoved}
                    />
                  )}
                </div>
              )}
              <div className={styles.cardBodyFooter}>
                {/* {rating && rating > 0 ? (
                  <CardRating className={styles.rating}>{rating}</CardRating>
                ) : null} */}
                <CardSubtitle className={styles.subtitle}>{subtitle}</CardSubtitle>
                {/* <CardSubtitle className={styles.subtitle}>21 ч 36 мин</CardSubtitle> */}
              </div>
            </div>
          </CardOverlay>
        </FadeTransition>
      </CardImage>
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  MovieCardImageContainer.displayName = 'MovieCardImageContainer';
}
