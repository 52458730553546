'use client';

import { Fragment } from 'react';

import clsx from 'clsx';

import { breakpoints } from '@/app.config';
import LockSVG from '@/icons/LockSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useHover } from '@/hooks/useHover';

import type { OnlineTvList } from '@/api/types/home.types';

import { FadeTransition } from '@/components/Animations';
import { Badge } from '@/components/Badge';
import { Image } from '@/components/Image';

import {
  FavoriteButtonProps,
  FavoriteToggleButton,
} from '@/views/OnlineTvView/FavoriteToggleButton/FavoriteToggleButton';
import styles from '@/views/OnlineTvView/OnlineTvCard/OnlineTvCard.module.scss';

// =================================================================

interface OnlineTvCardProps extends Pick<FavoriteButtonProps, 'onSuccessLiked'> {
  channel: OnlineTvList;
  isPlayed?: boolean;
  handleChannelClick: (channel: OnlineTvList) => void;
}

// =================================================================

export const OnlineTvCard = (props: OnlineTvCardProps) => {
  const { channel, isPlayed, onSuccessLiked, handleChannelClick } = props;
  const { isFree, isHd, channelIcon, title, id, isFavourite } = channel;

  const { ref: elementRef, isHovered } = useHover<HTMLButtonElement>();

  const { userAccessToken } = useVerification();

  const isAuthenticated = !!userAccessToken;

  const handleChannelCardClick = () => {
    handleChannelClick(channel);
  };

  return (
    <button
      ref={elementRef}
      id={String(channel.id) + 'channel'}
      type="button"
      onClick={handleChannelCardClick}
      className={clsx({
        [styles.onlineTvCard]: true,
        [styles.isPlayed]: props.isPlayed,
      })}
    >
      <div className={styles.cardInner}>
        {!isFree && isAuthenticated && (
          <span className={styles.lockIcon}>
            <LockSVG />
          </span>
        )}
        <FadeTransition inProp={isHovered} timeout={500}>
          <Fragment>
            {isAuthenticated && (
              <FavoriteToggleButton
                id={id}
                isFavorite={isFavourite}
                onSuccessLiked={onSuccessLiked}
              />
            )}
          </Fragment>
        </FadeTransition>
        <Image
          aspectRatio={1 / 1}
          src={channelIcon}
          alt={title}
          fill
          sizes={`33vw, ${breakpoints.small} 25vw, ${breakpoints.medium} 17vw, ${breakpoints.large} 12.5vw`}
        />
        {isHd && (
          <div className={styles.badgeContainer}>
            <Badge position="default" variant="quality-tv">
              HD
            </Badge>
          </div>
        )}
        {isPlayed && (
          <div className={styles.playAnimation}>
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </div>
        )}
      </div>
    </button>
  );
};
