export const formatTime = (timeInSeconds: number, isDurationFormat: boolean = false) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  if (isDurationFormat) {
    let time = '';

    if (hours > 0) {
      time += `${hours} ч`;
    }

    time += ` ${minutes} мин`;

    return time;
  }

  const formattedHours = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};
