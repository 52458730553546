import type { SVGProps } from '@/types/common.types';

const HasNotCardSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="151"
      height="150"
      viewBox="0 0 151 150"
      fill="none"
      {...props}
    >
      <path
        d="M75.2391 150C116.631 150 150.185 116.421 150.185 75C150.185 33.5786 116.631 0 75.2391 0C33.8475 0 0.292969 33.5786 0.292969 75C0.292969 116.421 33.8475 150 75.2391 150Z"
        fill="#919191"
        fillOpacity="0.1"
      />
      <path
        d="M118.101 21.5171L28.5809 53.8523C25.4965 54.9664 23.8992 58.37 25.0133 61.4544L43.9002 113.743C45.0143 116.827 48.4179 118.424 51.5023 117.31L141.023 84.9751C144.107 83.861 145.704 80.4575 144.59 77.3731L125.703 25.0846C124.589 22.0003 121.186 20.403 118.101 21.5171Z"
        fill="#474749"
      />
      <path
        d="M121.498 56.2612L27.3721 70.3956C24.129 70.8826 21.8948 73.9064 22.3818 77.1495L30.6376 132.128C31.1246 135.371 34.1484 137.605 37.3915 137.118L131.517 122.984C134.76 122.497 136.995 119.473 136.508 116.23L128.252 61.2516C127.765 58.0085 124.741 55.7742 121.498 56.2612Z"
        fill="#878788"
        stroke="#878788"
        strokeWidth="1.05563"
        strokeMiterlimit="10"
      />
      <path
        d="M115.913 65.0061L107.99 66.1959C106.666 66.3946 105.755 67.6283 105.954 68.9515L107.143 76.8749C107.342 78.1981 108.576 79.1097 109.899 78.911L117.822 77.7211C119.146 77.5224 120.057 76.2887 119.858 74.9656L118.669 67.0421C118.47 65.719 117.236 64.8074 115.913 65.0061Z"
        fill="#474749"
      />
      <path
        d="M62.5374 112.48L39.3778 115.958C38.6514 116.067 38.1509 116.744 38.26 117.471L38.3635 118.16C38.4726 118.886 39.1499 119.387 39.8763 119.278L63.0359 115.8C63.7624 115.691 64.2628 115.013 64.1537 114.287L64.0503 113.598C63.9412 112.872 63.2639 112.371 62.5374 112.48Z"
        fill="#474749"
      />
      <path
        d="M78.1942 116.94L40.7223 122.567C39.8056 122.705 39.1741 123.559 39.3117 124.476C39.4494 125.393 40.3041 126.024 41.2208 125.887L78.6927 120.26C79.6094 120.122 80.2409 119.267 80.1033 118.351C79.9656 117.434 79.1109 116.802 78.1942 116.94Z"
        fill="#474749"
      />
      <path
        d="M86.6334 26.023L88.2962 25.2834C88.8504 25.0985 88.8504 24.359 88.2962 24.1741L86.6334 23.4345C84.601 22.5101 82.9382 20.8461 82.0145 18.8123L81.2754 17.1483C81.0907 16.5937 80.3516 16.5937 80.1669 17.1483L79.4279 18.8123C78.5041 20.8461 76.8413 22.5101 74.809 23.4345L73.1462 24.1741C72.5919 24.359 72.5919 25.0985 73.1462 25.2834L74.809 26.023C76.8413 26.9474 78.5041 28.6114 79.4279 30.6452L80.1669 32.3092C80.3516 32.8639 81.0907 32.8639 81.2754 32.3092L82.0145 30.6452C82.7535 28.6114 84.4163 26.9474 86.6334 26.023Z"
        fill="#272729"
      />
      <path
        d="M68.7084 15.4843L69.8169 14.9296C70.1864 14.7447 70.1864 14.3749 69.8169 14.19L68.7084 13.8203C67.2303 13.2656 66.1218 12.1563 65.5675 10.6771L65.0133 9.56781C64.8285 9.19804 64.459 9.19804 64.2743 9.56781L63.9047 10.6771C63.1657 12.1563 62.0572 13.2656 60.7639 13.8203L59.4706 14.3749C59.2858 14.3749 59.2858 14.9296 59.4706 14.9296L60.5791 15.4843C62.0572 16.0389 63.1657 17.1483 63.9047 18.6274L64.459 19.7367C64.6438 20.1065 65.0133 20.1065 65.198 19.7367L65.5675 18.6274C66.1218 17.1483 67.2303 16.0389 68.7084 15.4843Z"
        fill="#272729"
      />
      <path
        d="M82.9362 4.76089L83.8599 4.39111C84.0447 4.20622 84.0447 4.02133 83.8599 3.83644L82.9362 3.46667C81.8276 3.09689 81.0886 2.17244 80.5343 1.06311L80.1648 0.138667C79.9801 -0.0462222 79.7953 -0.0462222 79.6106 0.138667L79.241 1.06311C78.8715 2.17244 77.9477 2.912 76.8392 3.46667L76.2849 3.83644C75.9154 4.02133 75.9154 4.39111 76.2849 4.39111L77.2087 4.76089C78.3173 5.13066 79.0563 6.05511 79.6106 7.16444L79.9801 8.08889C80.1648 8.27377 80.3496 8.27377 80.5343 8.08889L80.9038 7.16444C81.0886 6.05511 82.0124 5.13066 82.9362 4.76089Z"
        fill="#272729"
      />
    </svg>
  );
};

export default HasNotCardSVG;
