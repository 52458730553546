'use client';

import { useTranslations } from 'next-intl';

import { profileLinks } from '@/app.config';

import { UserAvatar } from '@/components/Avatars/UserAvatar';
import { Dropdown } from '@/components/Dropdown/Dropdown';
import { NavLink } from '@/components/NavLink';

import styles from '@/layout/UserDropdownMenu/UserDropdownMenu.module.scss';

import { useNetwork } from '@/contexts/NetworkContext';

// =================================================================

export const UserDropdownMenu = () => {
  const t = useTranslations();

  const { isTasix } = useNetwork();

  return (
    <div className={styles.profileDropdown}>
      <Dropdown trigger="click" position="bottom-right">
        <Dropdown.Label title="User-dropdown" arrowClassName={styles.arrowIcon}>
          <UserAvatar as="div" />
        </Dropdown.Label>
        <Dropdown.Menu>
          {profileLinks.map((link, index) => {
            const Icon = link.icon;

            if (!link.isTasixOnly && !isTasix) {
              return null;
            }

            return (
              <Dropdown.Option as="li" key={index} className={styles.listItem}>
                <NavLink
                  href={link.href}
                  className={styles.dropdownItem}
                  activeClassName={styles.active}
                  data-test-id={link.title}
                >
                  <span className={styles.iconWrapper}>
                    <Icon />
                  </span>
                  {t(link.title)}
                </NavLink>
              </Dropdown.Option>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
