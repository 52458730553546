'use client';

import { type MutableRefObject, type MouseEventHandler, forwardRef } from 'react';

import clsx from 'clsx';

import { Link } from '@/navigation';
import type { ChildrenProps } from '@/types/common.types';

import { useHover } from '@/hooks/useHover';
import { useMergeRefs } from '@/hooks/useMergeRefs';

import styles from '@/components/Buttons/Button.module.scss';

// ----------------------------------------------------------------

const buttonVariants = [
  'primary-gradient',
  'primary',
  'secondary',
  'secondary-red',
  'secondary-yellow',
  'secondary-white',
  'text-yellow',
  'outline',
] as const;

type CommonProps = {
  title?: string;
  className?: string;
  variant: (typeof buttonVariants)[number];
  size: 'xs' | 'sm' | 'md' | 'lg';
  shape?: 'circle' | 'round';
  bordered?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLAnchorElement>;
} & ChildrenProps;

export type ButtonProps = CommonProps &
  (
    | {
        as: 'button';
        type?: 'button' | 'reset' | 'submit';
        href?: never;
        replace?: never;
        form?: string;
      }
    | {
        as: 'link';
        replace?: boolean;
        href: string;
        type?: never;
        form?: never;
      }
  );

// ----------------------------------------------------------------

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (props, ref) => {
    const {
      as,
      title,
      children,
      size,
      variant,
      className,
      onClick,
      shape,
      bordered = false,
      disabled = false,
    } = props;

    const { ref: elementRef, isHovered } = useHover<HTMLAnchorElement | HTMLButtonElement>();

    const buttonRef = useMergeRefs(ref, elementRef);

    const classes = clsx(
      styles.button,
      styles[variant],
      styles[size],
      bordered && styles.bordered,
      disabled && styles.disabled,
      shape && styles[shape],
      className,
      isHovered && styles.hovered,
    );

    if (as === 'button') {
      return (
        <button
          ref={buttonRef as any}
          type={props.type || 'button'}
          title={title}
          aria-label={title}
          onClick={onClick as MouseEventHandler<HTMLButtonElement>}
          className={classes}
          disabled={disabled}
          form={props.form}
          onDoubleClick={event => event.stopPropagation()}
        >
          {children}
        </button>
      );
    }

    if (as === 'link') {
      return (
        <Link
          ref={ref as any}
          title={title}
          href={props.href}
          replace={props.replace}
          prefetch={false}
          onClick={onClick as MouseEventHandler<HTMLAnchorElement>}
          className={classes}
        >
          {children}
        </Link>
      );
    }

    return null;
  },
);
