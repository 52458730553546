/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';

import styles from '@/views/OnlineTvView/OnlineTvCard/OnlineTvCard.module.scss';

// =================================================================

export const OlineTvCardSkelton = () => (
  <div className={clsx(styles.onlineTvCard, styles.isSkelton)}>
    <div className={styles.skeltonCard}>
      <img
        src="/assets/images/tv-card-placeholder.png"
        alt="tv-card-skelton"
        className={styles.channelLogo}
      />
    </div>
  </div>
);
