import { ReactNode } from 'react';

import AccountSVG from '@/icons/AccountSVG';
import ArrowTopSVG from '@/icons/ArrowTopSVG';
import BankCardSVG from '@/icons/BankCardSVG';
import CollectionsSVG from '@/icons/CollectionsSVG';
import CompetitionsSVG from '@/icons/CompetitionsSVG';
import DevicePCSVG from '@/icons/DevicePCSVG';
import { EmailSVG } from '@/icons/EmailSVG';
import { FacebookSVG } from '@/icons/FacebookSVG';
import FavoriteSVG from '@/icons/FavoriteSVG';
import FavoriteSecondarySVG from '@/icons/FavoriteSecondarySVG';
import HelpSVG from '@/icons/HelpSVG';
import HistorySVG from '@/icons/HistorySVG';
import { InstagramSVG } from '@/icons/InstagramSVG';
import { LocationSVG } from '@/icons/LocationSVG';
import MobileCatalogSVG from '@/icons/MobileCatalogSVG';
import OnlineTvSVG from '@/icons/OnlineTvSVG';
import OnlineTvSecondarySVG from '@/icons/OnlineTvSecondarySVG';
import PhoneSVG from '@/icons/PhoneSVG';
import PrivacyPolicySVG from '@/icons/PrivacyPolicySVG';
import PromoSVG from '@/icons/PromoSVG';
import SearchSecondarySVG from '@/icons/SearchSecondarySVG';
import { TelegramSVG } from '@/icons/TelegramSVG';
import VideoClipSVG from '@/icons/VideoClipSVG';
import { YoutubeSVG } from '@/icons/YoutubeSVG';
import type { WindowSizes } from '@/types/common.types';

import { ActorRolesEnum } from '@/enums/actor-roles.enums';
import { BannerLinkEnum } from '@/enums/banner-link.enums';
import { ModuleTypesEnum } from '@/enums/module-types.enums';
import { SubscriptionDomain } from '@/enums/profile-subscriptions-paths.enums';
import { QualityEnum } from '@/enums/quality.enums';
import { ProfileDomain } from '@/enums/user-profile-paths.enums';
import { UsersHistoryDomain } from '@/enums/users-history.enums';

export type ErrorType = Array<{
  title: string;
  message: string;
  id: number;
  placeholder: string;
}>;

export type FooterLinksType = {
  label: string;
  items: {
    firstRow: Array<{ label: string; href: string; id: string | number }>;
    secondRow?: Array<{ label: string; href: string; id: string | number }>;
  };
};

// =================================================================

export const sidebarNavigationList = [
  {
    title: 'header.navigation.onlineTV',
    href: '/online-tv?categoryId=15',
    isVisible: true,
    icon: OnlineTvSecondarySVG,
  },
  {
    title: 'header.navigation.competitions',
    href: '/movies/3',
    isVisible: true,
    icon: CompetitionsSVG,
  },
  {
    title: 'header.navigation.catalogAndGenres',
    href: '/movies/4',
    isVisible: true,
    icon: MobileCatalogSVG,
  },
  {
    title: 'header.navigation.videoClips',
    href: '/movies/5',
    isVisible: true,
    icon: VideoClipSVG,
  },
] as const;

export const mobileNavigationList = [
  {
    title: 'header.navigation.main',
    href: '/',
    isVisible: true,
    icon: ArrowTopSVG,
    onlyAuthorization: false,
  },
  {
    title: 'header.navigation.onlineTVMobile',
    href: '/online-tv?categoryId=15',
    isVisible: true,
    icon: OnlineTvSVG,
    onlyAuthorization: false,
  },
  {
    title: 'header.navigation.my',
    href: '/users-history',
    isVisible: true,
    icon: FavoriteSecondarySVG,
    onlyAuthorization: true,
  },
  {
    title: 'header.navigation.search',
    href: '/search',
    isVisible: true,
    icon: SearchSecondarySVG,
    onlyAuthorization: false,
  },
] as const;

export const navigationList = [
  {
    title: 'header.navigation.onlineTV',
    href: '/online-tv?categoryId=15',
    isVisible: true,
    icon: <OnlineTvSVG height={20} width={20} />,
    linkTitle: 'Online-tv',
  },
  {
    title: 'header.navigation.collections',
    href: '/collections/11',
    icon: <CollectionsSVG height={20} width={20} />,
    isVisible: true,
    linkTitle: 'Collections',
  },
  {
    title: 'header.navigation.subscriptions',
    href: '/subscriptions',
    icon: <PromoSVG height={20} width={20} />,
    isVisible: true,
    linkTitle: 'Subscriptions',
  },
  // {
  //   title: 'header.navigation.promocode',
  //   href: '/user-profile/subscriptions',
  //   icon: <DiscountSVG height={20} width={20} />,
  //   isVisible: true,
  // },
  // {
  //   title: 'header.navigation.notification',
  //   href: '/movies/5',
  //   isVisible: true,
  //   icon: NotificationsSVG,
  // },
] as const;

export const profileLinks = [
  {
    title: 'layout.header.profile.account',
    href: '/user-profile/account',
    icon: AccountSVG,
    isTasixOnly: false,
  },
  {
    title: 'layout.header.profile.subscriptions',
    href: '/user-profile/subscriptions',
    icon: PromoSVG,
    isTasixOnly: false,
  },
  {
    title: 'layout.header.profile.favorites',
    href: '/user-profile/favorites',
    icon: FavoriteSVG,
    isTasixOnly: false,
  },
  {
    title: 'layout.header.profile.history',
    href: '/user-profile/history',
    icon: HistorySVG,
    isTasixOnly: false,
  },
  {
    title: 'layout.header.profile.help',
    href: '/user-profile/help',
    icon: HelpSVG,
    isTasixOnly: true,
  },
];

export const profileNavigationsLinks = [
  {
    title: 'layout.header.profile.account',
    href: '/user-profile/account',
    icon: AccountSVG,
    isTasixOnly: false,
  },
  // {
  //   title: 'layout.header.profile.setting',
  //   href: '/user-profile/setting',
  //   icon: SettingSVG,
  //   isTasixOnly: true,
  // },
  {
    title: 'layout.header.profile.sessions',
    href: '/user-profile/sessions',
    icon: DevicePCSVG,
    isTasixOnly: false,
  },
  {
    title: 'layout.header.profile.subscriptions',
    href: '/user-profile/subscriptions',
    icon: PromoSVG,
    isTasixOnly: false,
  },
  {
    title: 'layout.header.profile.paymentMethod',
    href: '/user-profile/payments',
    icon: BankCardSVG,
    isTasixOnly: false,
  },
  {
    title: 'layout.header.profile.history',
    href: '/user-profile/history',
    icon: HistorySVG,
    isTasixOnly: false,
  },
  {
    title: 'layout.header.profile.favorites',
    href: '/user-profile/favorites',
    icon: FavoriteSVG,
    isTasixOnly: false,
  },
  // {
  //   title: 'layout.header.profile.goals',
  //   href: '/user-profile/goals',
  //   icon: StarSVG,
  //   isTasixOnly: false,
  // },
  {
    title: 'layout.header.profile.help',
    href: '/user-profile/help',
    icon: HelpSVG,
    isTasixOnly: false,
  },
  // {
  //   title: 'layout.header.profile.documents',
  //   href: '/user-profile/documents',
  //   icon: DocumentSVG,
  //   isTasixOnly: false,
  // },
];

export const modules = [
  {
    id: 'all',
    title: 'header.navigation.all',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '3',
    title: 'header.navigation.movies',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '4',
    title: 'header.navigation.serials',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '1',
    title: 'header.navigation.onlineTV',
    moduleType: 'online-tv',
    isVisible: true,
  },
  {
    id: '5',
    title: 'header.navigation.cartoons',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '6',
    title: 'header.navigation.uzVideo',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '12',
    title: 'header.profile.subscriptions',
    moduleType: 'subscriptions',
    isVisible: true,
  },
  {
    id: '7',
    title: 'header.navigation.anime',
    moduleType: 'movies',
    isVisible: false,
  },
  {
    id: '11',
    title: 'header.navigation.collections',
    moduleType: 'collections',
    isVisible: false,
  },
] as const;

export const privacyPolicyPath: {
  title: string;
  href: string;
  icon: ReactNode;
  isTasixOnly: boolean;
} = {
  title: 'header.navigation.privacyPolicy',
  href: '/privacy-policy',
  icon: <PrivacyPolicySVG />,
  isTasixOnly: false,
};

// =================================================================

export const moviesModulesIds = ['all', '3', '4', '5', '6', '7'];

// =================================================================

export const moduleTypes: Record<ModuleTypesEnum, string> = {
  movies: 'movies',
  onlineTv: 'online-tv',
  collection: 'collection',
  collections: 'collections',
} as const;

// =================================================================

export const breakpoints: Record<WindowSizes, string> = {
  extraExtraLarge: '(min-width: 1400px)',
  extraLarge: '(min-width: 1200px)',
  large: '(min-width: 992px)',
  medium: '(min-width: 768px)',
  small: '(min-width: 576px)',
  extraSmall: '(min-width: 0px)',
} as const;

// =================================================================

export const qualityTitles: Record<QualityEnum, string> = {
  sd: 'sd',
  hd: 'hd',
  full_hd: 'full hd',
  three_d: '3d',
  four_k: '4k',
} as const;

// =================================================================

export const roleTitles: Record<ActorRolesEnum, string> = {
  actor: 'Актёр',
  director: 'Режиссёр',
  scenarist: 'Сценарист',
  producer: 'Продюсер',
} as const;

// =================================================================

export const bannerLinkTypes: Record<BannerLinkEnum, number> = {
  externalLink: 0,
  movie: 1,
  collection: 2,
} as const;

// =================================================================

export const errorTypes: ErrorType = [
  {
    title: 'Плохое изображение',
    message: 'bad_picture',
    id: 0,
    placeholder: 'пожалуйста, подробно опишите проблему с содержимым файла',
  },
  {
    title: 'Плохой звук',
    message: 'bad_sound',
    id: 1,
    placeholder: 'пожалуйста, подробно опишите проблему с содержимым файла',
  },
  {
    title: 'Не работает',
    message: 'not_working',
    id: 2,
    placeholder: 'пожалуйста, подробно опишите проблему с содержимым файла',
  },
  {
    title: 'Другое',
    message: 'other',
    id: 3,
    placeholder: 'пожалуйста, подробно опишите проблему с содержимым файла',
  },
] as const;

// =================================================================

export const profileDomains: Array<{
  title: string;
  domain: ProfileDomain;
  isTasixOnly: boolean;
}> = [
  {
    title: 'header.profile.account',
    domain: ProfileDomain.Account,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.subscriptions',
    domain: ProfileDomain.Subscriptions,
    isTasixOnly: true,
  },
  {
    title: 'header.profile.favorites',
    domain: ProfileDomain.Favorites,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.history',
    domain: ProfileDomain.History,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.sessions',
    domain: ProfileDomain.Sessions,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.paymentMethod',
    domain: ProfileDomain.Payments,
    isTasixOnly: true,
  },
];

export const subscriptionDomains: Array<{
  title: string;
  domain: SubscriptionDomain;
}> = [
  {
    title: 'header.subscriptions.purchasing',
    domain: SubscriptionDomain.Purchasing,
  },
  {
    title: 'header.subscriptions.active',
    domain: SubscriptionDomain.Active,
  },
  {
    title: 'header.subscriptions.autoRenewal',
    domain: SubscriptionDomain.AutoRenewal,
  },
  {
    title: 'header.subscriptions.history',
    domain: SubscriptionDomain.History,
  },
  {
    title: 'header.subscriptions.promoCode',
    domain: SubscriptionDomain.promoCode,
  },
];

export const usersHistoryDomains: Array<{
  title: string;
  domain: any;
  id: keyof typeof UsersHistoryDomain;
}> = [
  {
    title: 'header.profile.favorites',
    domain: ProfileDomain.Favorites,
    id: 'favourites',
  },
  {
    title: 'header.profile.history',
    domain: ProfileDomain.History,
    id: 'histories',
  },
];

// =================================================================

export const contacts: Array<{
  title: string;
  type: 'phone' | 'email' | 'location';
  value: string;
  icon: ReactNode;
  label: string;
}> = [
  {
    title: 'phoneNumber',
    type: 'phone',
    value: '+998 (71) 252-74-77',
    icon: <PhoneSVG />,
    label: '+998 (71) 252-74-77',
  },
  {
    title: 'emailAddress',
    type: 'email',
    value: 'info@cinerama.uz',
    label: 'info@cinerama.uz',
    icon: <EmailSVG />,
  },
  {
    title: 'address',
    type: 'location',
    value:
      'https://yandex.uz/maps/10335/tashkent/house/YkAYdAFgQUwHQFprfX9zeH1kZg==/?ll=69.261149%2C41.328987&z=19.33',
    label: 'ул. Зульфияханум, 24/2',
    icon: <LocationSVG />,
  },
];

// =================================================================

export const socials: Array<{
  icon: ReactNode;
  label: string;
  title: string;
  value: string;
}> = [
  {
    label: process.env.NEXT_PUBLIC_CINERAMA_INSTAGRAM_URL as string,
    title: 'Instagram',
    value: process.env.NEXT_PUBLIC_CINERAMA_INSTAGRAM_URL as string,
    icon: <InstagramSVG />,
  },
  {
    label: process.env.NEXT_PUBLIC_CINERAMA_TELEGRAM_URL as string,
    title: 'Telegram',
    value: process.env.NEXT_PUBLIC_CINERAMA_TELEGRAM_URL as string,
    icon: <TelegramSVG />,
  },
  {
    label: process.env.NEXT_PUBLIC_CINERAMA_FACEBOOK_URL as string,
    title: 'Facebook',
    value: process.env.NEXT_PUBLIC_CINERAMA_FACEBOOK_URL as string,
    icon: <FacebookSVG />,
  },
  {
    label: process.env.NEXT_PUBLIC_CINERAMA_YOUTUBE_URL as string,
    title: 'Youtube',
    value: process.env.NEXT_PUBLIC_CINERAMA_YOUTUBE_URL as string,
    icon: <YoutubeSVG />,
  },
];

// =================================================================

export const footerNavbarLinks: FooterLinksType[] = [
  {
    label: 'categories',
    items: {
      firstRow: [
        { label: 'movies', href: '/movies/3', id: 1 },
        { label: 'series', href: '/movies/4', id: 1.1 },
        { label: 'cartoons', href: '/movies/5', id: 1.2 },
        { label: 'anime', href: '/movies/7', id: 1.3 },
        { label: 'uzbekkino', href: '/movies/6', id: 1.4 },
        { label: 'collections', href: '/collections/11', id: 1.5 },
      ],
    },
  },
  {
    label: 'genres',
    items: {
      firstRow: [
        { label: 'biography', href: '/genre/2', id: 2 },
        { label: 'western', href: '/genre/4', id: 2.1 },
        { label: 'drama', href: '/genre/9', id: 2.2 },
        { label: 'comedy', href: '/genre/15', id: 2.3 },
        { label: 'crime', href: '/genre/18', id: 2.4 },
        { label: 'horror', href: '/genre/44', id: 2.5 },
      ],
      secondRow: [
        { label: 'cookingShow', href: '/genre/19', id: 3 },
        { label: 'thriller', href: '/genre/43', id: 3.1 },
        { label: 'fantasy', href: '/genre/47', id: 3.2 },
        { label: 'cybersport', href: '/genre/14', id: 3.3 },
        { label: 'children', href: '/genre/7', id: 3.4 },
        { label: 'podcast', href: '/genre/245', id: 3.5 },
      ],
    },
  },
  {
    label: 'additional',
    items: {
      firstRow: [
        { label: 'onlineTv', href: '/online-tv?categoryId=15', id: 4 },
        { label: 'subscriptions', href: '/subscriptions', id: 4.1 },
        { label: 'apps', href: '/apps', id: 4.2 },
        { label: 'promocode', href: '', id: 'promocode' },
        { label: 'cinemas', href: '/cinema', id: 4.4 },
      ],
    },
  },
];
