import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import { ClassNameProps } from '@/types/common.types';

import styles from '@/components/Form/FormCode.module.scss';

// =================================================================

interface CodeInputProps extends ClassNameProps {
  length?: number;
  onComplete?: (code: string) => void;
  placeholder?: string;
  name: string;
  autoFocus?: boolean;
}

// =================================================================

export const CodeInput: React.FC<CodeInputProps> = (props: CodeInputProps) => {
  const { length = 6, onComplete, className, placeholder, name, autoFocus } = props;

  const [code, setCode] = useState<string[]>(new Array(length).fill(''));
  const inputs = useRef<Array<HTMLInputElement | null>>([]);

  const { control } = useFormContext();

  const {
    field: { onChange, onBlur, disabled },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    if (/^\d$/.test(value) || value === '') {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      onChange(newCode.join(''));

      if (value !== '' && index < length - 1) {
        inputs.current[index + 1]?.focus();
      }

      if (newCode.every(num => num !== '') && onComplete) {
        onComplete(newCode.join(''));
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && code[index] === '' && index > 0) {
      inputs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    if (autoFocus && inputs.current[0]) {
      inputs.current[0].focus();
    }
  }, [autoFocus]);

  return (
    <div className={clsx(styles.codeInput, { [styles.error]: error }, className)}>
      {code.map((num, index) => (
        <input
          key={index}
          ref={input => {
            inputs.current[index] = input;
          }}
          type="text"
          inputMode="numeric"
          value={num}
          maxLength={1}
          onChange={e => handleChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          onBlur={onBlur}
          className={clsx(styles.codeInput, { [styles.error]: error })}
          placeholder={placeholder}
          disabled={disabled}
        />
      ))}
      {/* {error && <span className={styles.errorMessage}>{error.message}</span>} */}
    </div>
  );
};
